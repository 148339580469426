import {setupFrontend} from '@shared-frontend/setup_frontend';

import {App} from '@src/components/app';
import {theme} from '@src/components/core/theme';

setupFrontend({
  appComponent: App,
  theme,
  notifications: {
    duration: 4000,
  },
});
