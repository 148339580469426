import {FC, MouseEventHandler, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {CityLookupResult} from '@shared/api/definitions/public_api/hoobiiz_api';

import {Dropdown} from '@shared-frontend/components/core/dropdown';
import {Radios} from '@shared-frontend/components/core/radios';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizSearchLocationAroundMe} from '@src/components/ui/hoobiiz_search_location_around_me';
import {HoobiizSearchLocationCity} from '@src/components/ui/hoobiiz_search_location_city';
import {
  HoobiizActivitySortingMetadataType,
  HoobiizUserLocation,
  setActivitySortingMetadata,
  useActivitySortingMetadata,
} from '@src/lib/stores';

interface HoobiizSearchLocationProps {}

export const HoobiizSearchLocation: FC<HoobiizSearchLocationProps> = () => {
  const sortingMetadata = useActivitySortingMetadata();
  const {isDesktop} = useResponsive();

  // Functions to trigger setLocation
  const updateLocation = useCallback(
    (pos: HoobiizUserLocation) => {
      if (sortingMetadata.type !== HoobiizActivitySortingMetadataType.AroundMe) {
        return;
      }
      setActivitySortingMetadata({...sortingMetadata, location: pos});
    },
    [sortingMetadata]
  );
  const updateCity = useCallback(
    (city: CityLookupResult | undefined) => {
      if (sortingMetadata.type !== HoobiizActivitySortingMetadataType.City) {
        return;
      }
      setActivitySortingMetadata({...sortingMetadata, city});
    },
    [sortingMetadata]
  );

  // Synchronise all available locations with internal state
  const allSortings = useMemo<
    {
      value: HoobiizActivitySortingMetadataType;
      label: string | React.JSX.Element;
    }[]
  >(
    () => [
      {
        value: HoobiizActivitySortingMetadataType.Everywhere,
        label: <DropdownLabel>N'importe où</DropdownLabel>,
      },
      {
        value: HoobiizActivitySortingMetadataType.AroundMe,
        label: (
          <HoobiizSearchLocationAroundMe
            location={
              sortingMetadata.type === HoobiizActivitySortingMetadataType.AroundMe
                ? sortingMetadata.location
                : {type: 'init'}
            }
            onChange={updateLocation}
            disabled={sortingMetadata.type !== HoobiizActivitySortingMetadataType.AroundMe}
          />
        ),
      },
      {
        value: HoobiizActivitySortingMetadataType.City,
        label: (
          <HoobiizSearchLocationCity
            city={
              sortingMetadata.type === HoobiizActivitySortingMetadataType.City
                ? sortingMetadata.city
                : undefined
            }
            onChange={updateCity}
            disabled={sortingMetadata.type !== HoobiizActivitySortingMetadataType.City}
          />
        ),
      },
    ],
    [sortingMetadata, updateLocation, updateCity]
  );

  const setSearchLocation = useCallback(
    (locationType: HoobiizActivitySortingMetadataType | undefined) => {
      if (locationType === undefined) {
        return;
      }
      if (locationType === HoobiizActivitySortingMetadataType.Everywhere) {
        setActivitySortingMetadata({type: locationType});
      } else if (locationType === HoobiizActivitySortingMetadataType.AroundMe) {
        setActivitySortingMetadata({type: locationType, location: {type: 'init'}});
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      else if (locationType === HoobiizActivitySortingMetadataType.City) {
        setActivitySortingMetadata({type: locationType, city: undefined});
      }
    },
    []
  );

  const handleRadiosClick = useCallback<MouseEventHandler>(evt => {
    evt.stopPropagation();
  }, []);

  const searchLocationElement = useMemo(() => {
    if (
      sortingMetadata.type === HoobiizActivitySortingMetadataType.AroundMe &&
      sortingMetadata.location.type === 'success'
    ) {
      return `Autour de moi`;
    }
    if (sortingMetadata.type === HoobiizActivitySortingMetadataType.City && sortingMetadata.city) {
      return `À ${sortingMetadata.city.description}`;
    }
    return `N'importe où`;
  }, [sortingMetadata]);

  const button = useCallback(
    (isDropdownShown: boolean) => {
      return (
        <DropdownButton $isFocused={isDropdownShown} $isDesktop={isDesktop}>
          {searchLocationElement}
        </DropdownButton>
      );
    },
    [isDesktop, searchLocationElement]
  );

  return (
    <Dropdown
      button={button}
      content={
        <DropdownForm $isDesktop={isDesktop}>
          <Radios<HoobiizActivitySortingMetadataType>
            value={sortingMetadata.type}
            values={allSortings}
            syncState={setSearchLocation}
            column
            onClick={handleRadiosClick}
          />
        </DropdownForm>
      }
      align="left"
    />
  );
};

HoobiizSearchLocation.displayName = 'HoobiizSearchLocation';

const DropdownButton = styled.div<{
  $isFocused?: boolean;
  $isDesktop: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  padding-right: 16px;
  padding-left: 16px;
  height: 46px;
  color: ${Colors.Black};
  width: ${({$isDesktop}) => ($isDesktop ? '200px' : 'auto')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${p =>
    p.$isFocused &&
    `box-shadow: 0 0 0 ${p.theme.input.focusOutlineWidth}px ${p.theme.input.focusOutlineColor};`}
  border: solid 1px ${p =>
    p.$isFocused ? p.theme.input.focusBorderColor : p.theme.input.borderColor};
  &:hover {
    border-color: ${p =>
      p.$isFocused ? p.theme.input.focusBorderColor : p.theme.input.hoverBorderColor};
  }
`;

const DropdownForm = styled.div<{$isDesktop: boolean}>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-width: ${({$isDesktop}) => ($isDesktop ? '340px' : 'auto')};
  border-radius: 8px;
  padding: 8px 16px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
`;

const DropdownLabel = styled.div`
  display: flex;
  align-items: center;
  height: 25.5px;
`;
