import styled from 'styled-components';

import {Image} from '@shared-frontend/components/core/image';

export const Table = styled.table<{
  $isLoading?: boolean;
}>`
  position: relative;
  width: 100%;
  border-collapse: collapse;

  td {
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
  }
  th {
    background-color: ${p => p.theme.main.accentColor};
    color: ${p => p.theme.main.accentTextColor};
    position: sticky;
    top: 0;
  }

  tbody {
    tr {
      cursor: pointer;
      &:nth-child(even),
      &:hover {
        background-color: #f4f4f4;
      }
    }
    ${p =>
      p.$isLoading
        ? `
      position: relative;
      &:after {
        content: 'Chargement';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #ffffff99;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
        color: #00000066;
      }
    `
        : false}
  }
`;

export const TableHeaderCell = styled.div<{$clickable?: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 8px;
  height: 40px;
  user-select: none;
  ${p =>
    p.$clickable
      ? `
    cursor: pointer;
    &:hover {
      background-color: #00000010;
    }
    `
      : false}
`;

export const TableHeaderText = styled.div`
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;
`;

// TODO: overflow should always be hidden?
export const TableLineCell = styled.div<{$noWrapp?: boolean; $overflow?: 'scroll' | 'hidden'}>`
  padding: 8px;
  font-size: 17px;
  ${p => (p.$noWrapp ? 'white-space: nowrap;' : false)}
  max-height: 128px;
  overflow: ${({$overflow}) => $overflow ?? 'scroll'};
`;
export const TableLineCellCentered = styled(TableLineCell)`
  text-align: center;
`;

export const TableLineCellWithImage = styled(TableLineCell)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TableImage = styled(Image)`
  border-radius: 8px;
  box-shadow: 0 0 7px -1px #0000002e;
`;
