import {FC, useCallback, useEffect, useRef} from 'react';
import {styled} from 'styled-components';

import {normalizeString} from '@shared/lib/string_utils';
import {HoobiizUser} from '@shared/model/hoobiiz/hoobiiz_users';

import {Autocomplete} from '@shared-frontend/components/core/autocomplete';
import {usePrevious} from '@shared-frontend/lib/use_previous';

import {
  getAllUsers,
  getUserStoreState,
  loadAllUsers,
  useUserStoreState,
} from '@src/components/admin/user_and_group/user_store';
import {adminInputTheme} from '@src/components/core/theme';

interface FrontendUserPickerProps {
  user: HoobiizUser | undefined;
  setUser: (user: HoobiizUser | undefined) => void;
}

function userToInputString(user: HoobiizUser): string {
  return user.email;
}

function itemToKey(user: HoobiizUser): string {
  return user.userId;
}

export const FrontendUserPicker: FC<FrontendUserPickerProps> = ({user, setUser}) => {
  const {isLoaded, isLoading} = useUserStoreState();
  const prevIsLoading = usePrevious(isLoading);
  const onLoadingEndCallbacks = useRef<(() => void)[]>([]);
  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      for (const cb of onLoadingEndCallbacks.current) {
        cb();
      }
    }
  }, [prevIsLoading, isLoading, onLoadingEndCallbacks]);

  // Trigger a load of all users
  useEffect(loadAllUsers, []);

  const lookupItem = useCallback(async (input: string) => {
    // Wait in case we are loading or are not loaded yet
    const {isLoaded, isLoading} = getUserStoreState();
    if (!isLoaded || isLoading) {
      await new Promise<void>(resolve => {
        onLoadingEndCallbacks.current.push(resolve);
      });
    }

    // Lookup the users
    return (
      getAllUsers()
        ?.map(user => {
          const index = normalizeString(user.email).indexOf(normalizeString(input));
          return {user, index};
        })
        .filter(e => e.index >= 0)
        .sort((a, b) => a.index - b.index)
        .map(e => e.user) ?? []
    );
  }, []);

  const lookupInitial = useCallback(async () => {
    // Wait in case we are loading or are not loaded yet
    const {isLoaded, isLoading} = getUserStoreState();
    if (!isLoaded || isLoading) {
      await new Promise<void>(resolve => {
        onLoadingEndCallbacks.current.push(resolve);
      });
    }

    // Lookup the users
    return getAllUsers()?.sort((a, b) => a.email.localeCompare(b.email)) ?? [];
  }, []);

  const itemElement = useCallback((item: HoobiizUser, highlighted: boolean) => {
    return <Row $highlighted={highlighted}>{item.email}</Row>;
  }, []);

  return (
    <StyledAutocomplete
      item={user}
      itemToInputString={userToInputString}
      itemToKey={itemToKey}
      itemElement={itemElement}
      syncState={setUser}
      lookupItem={lookupItem}
      lookupInitial={lookupInitial}
      placeholder={
        !isLoaded && !isLoading
          ? 'Échec de la récupération des utilisateurs'
          : 'Ajouter un administrateur'
      }
      overrides={adminInputTheme}
      maxHeight="80vh"
      debounceMs={0}
      disabled={!isLoaded && !isLoading}
    />
  );
};

FrontendUserPicker.displayName = 'FrontendUserPicker';

const StyledAutocomplete = styled(Autocomplete<HoobiizUser>)`
  width: 100%;
`;

const Row = styled.div<{$highlighted: boolean}>`
  background-color: ${p => (p.$highlighted ? '#f0f0f0' : 'white')};
  padding: 16px;
  &:hover {
    background-color: #f0f0f0;
  }
`;
