import {FC} from 'react';
import styled from 'styled-components';

import {SanitizedHoobiizOrderItemItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizOrderItemItem, HoobiizOrderStatus} from '@shared/dynamo_model';
import {HoobiizUser} from '@shared/model/hoobiiz/hoobiiz_users';

import {NavButton} from '@shared-frontend/components/core/button';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {HoobiizOrderItem} from '@src/components/ui/hoobiiz_order_item';

interface AdminOrderModalProps {
  sanitizedOrderItem: SanitizedHoobiizOrderItemItem;
  orderItem: HoobiizOrderItemItem;
  user?: HoobiizUser | undefined;
}

export const AdminOrderModal: FC<AdminOrderModalProps> = ({
  sanitizedOrderItem,
  orderItem,
  user,
}) => {
  return (
    <OrderDetails>
      <OrderHeader>
        <OrderHeaderLine>
          <OrderHeaderLabel>Client</OrderHeaderLabel>
          {user === undefined ? (
            <div>Utilisateur introuvable</div>
          ) : (
            <div>{`${user.firstName} ${user.lastName} (${user.email})`}</div>
          )}
        </OrderHeaderLine>
        <OrderHeaderLine>
          <OrderHeaderLabel>Date d'achat</OrderHeaderLabel>
          <div>
            {sanitizedOrderItem.endedAt === undefined
              ? '-'
              : new Date(sanitizedOrderItem.endedAt).toLocaleString('fr-FR', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                })}
          </div>
        </OrderHeaderLine>
      </OrderHeader>
      <HoobiizOrderItem key={sanitizedOrderItem.id} orderItem={sanitizedOrderItem} />
      {orderItem.status === HoobiizOrderStatus.PendingConfirmation ? (
        <NavButton
          to={`/admin-order/${encodeURIComponent(orderItem.confirmationToken ?? '')}`}
          target="_blank"
        >
          Aller à la page de confirmation
        </NavButton>
      ) : (
        EmptyFragment
      )}
    </OrderDetails>
  );
};

AdminOrderModal.displayName = 'AdminOrderModal';

const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #ffffff;
  padding: 32px;
  width: 95vw;
  max-width: 800px;
`;

const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OrderHeaderLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const OrderHeaderLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
