import {FC} from 'react';

import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {ComponentClass} from '@shared-frontend/lib/react';

interface RegisterLinkPageModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
}

export const RegisterLinkPageModeDefault: FC<RegisterLinkPageModeDefaultProps> = ({wrapper}) => {
  return (
    <ModeDefaultTemplate wrapper={wrapper}>Finalisation de l'inscription...</ModeDefaultTemplate>
  );
};

RegisterLinkPageModeDefault.displayName = 'RegisterLinkPageModeDefault';
