import {FC, FormEventHandler, Fragment, ReactNode} from 'react';
import styled from 'styled-components';

import {neverHappens} from '@shared/lib/type_utils';
import {MfaCode} from '@shared/model/auth/user';

import {LoginFormMode} from '@shared-frontend/components/auth/base/login_page';
import {
  ModeHeroAuthWhiteLabeling,
  ModeHeroButtonOverrides,
  ModeHeroInputOverrides,
  ModeHeroNavLinkOverrides,
  ModeHeroTemplate,
} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input, InputProps} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';

interface LoginPageModeHeroProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
  formMode: LoginFormMode;
  emailInputProps: InputProps<string | undefined>;
  passwordInputProps: InputProps<string | undefined>;
  mfaCodeInputProps: InputProps<MfaCode | undefined>;
  isSubmitting: boolean;
  handleEmailSubmit: FormEventHandler<HTMLFormElement>;
  handleLoginSubmit: FormEventHandler<HTMLFormElement>;
}

export const LoginPageModeHero: FC<LoginPageModeHeroProps> = props => {
  const {
    whiteLabeling,
    formMode,
    emailInputProps,
    passwordInputProps,
    mfaCodeInputProps,
    isSubmitting,
    handleEmailSubmit,
    handleLoginSubmit,
  } = props;

  let formContent: ReactNode;
  if (formMode === 'email-form' || formMode === 'email-password-form' || formMode === 'mfa-form') {
    formContent = (
      <Fragment>
        <FormInput>
          <Input
            {...emailInputProps}
            overrides={ModeHeroInputOverrides}
            placeholder="Votre adresse mail"
          />
        </FormInput>
        <Spacing hidden={formMode !== 'email-password-form'} height={12} />
        <FormInput hidden={formMode !== 'email-password-form'}>
          <Input
            {...passwordInputProps}
            overrides={ModeHeroInputOverrides}
            placeholder="Votre mot de passe"
          />
        </FormInput>
        <Spacing hidden={formMode !== 'mfa-form'} height={12} />
        <FormInput hidden={formMode !== 'mfa-form'}>
          <Input
            {...mfaCodeInputProps}
            overrides={ModeHeroInputOverrides}
            placeholder="Votre code de sécurité"
          />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <StyledNavLink to="/terms" overrides={ModeHeroNavLinkOverrides}>
            mentions légales
          </StyledNavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting} overrides={ModeHeroButtonOverrides}>
          {formMode === 'email-password-form' ? 'Connexion' : 'Continuer'}
        </Button>
        <Spacing height={28} />
        <BottomLinks>
          <StyledNavLink to="/forgot-password" overrides={ModeHeroNavLinkOverrides}>
            Mot de passe oublié ?
          </StyledNavLink>
          <StyledNavLink to="/register" overrides={ModeHeroNavLinkOverrides}>
            Inscription
          </StyledNavLink>
        </BottomLinks>
      </Fragment>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (formMode === 'wait-for-magic-link') {
    formContent = (
      <Fragment>
        <FormTitle>Connexion en cours...</FormTitle>
        <Spacing height={24} />
        <Message>
          Nous vous avons envoyé un email contenant un lien. Cliquez sur le lien pour vous
          connecter. Vous pouvez fermer cette page.
        </Message>
      </Fragment>
    );
  } else {
    neverHappens(formMode);
  }

  return (
    <ModeHeroTemplate whiteLabeling={whiteLabeling}>
      <Form onSubmit={formMode === 'email-form' ? handleEmailSubmit : handleLoginSubmit}>
        {formContent}
      </Form>
    </ModeHeroTemplate>
  );
};

LoginPageModeHero.displayName = 'LoginModeHero';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 160%;
  color: ${p => p.theme.main.accentTextColor};
`;

const Message = styled.p`
  font-weight: 400;
  color: ${p => p.theme.main.accentTextColor};
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;

const BottomLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledNavLink = styled(NavLink)``;
