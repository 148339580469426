import {FC, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizOpeningHours, HoobiizOpeningHoursOverride} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {useLiveRef} from '@shared-frontend/lib/use_live_ref';

import {HoobiizOpeningHoursOverrideForm} from '@src/components/admin/form/hoobiiz_opening_hours_override_form';

interface HoobiizOpeningHoursOverridesFormProps {
  overrides?: HoobiizOpeningHoursOverride[];
  onChange?: (data: HoobiizOpeningHours['overrides']) => void;
  disabled?: boolean;
}

export const HoobiizOpeningHoursOverridesForm: FC<
  HoobiizOpeningHoursOverridesFormProps
> = props => {
  const {disabled, overrides, onChange} = props;

  const onChangeRef = useLiveRef(onChange);

  const [data, setData] = useState<(HoobiizOpeningHoursOverride | undefined)[]>(overrides ?? []);

  const handleChange = useCallback(
    (trackIndex: number, data: HoobiizOpeningHoursOverride | undefined) => {
      setData(current => current.map((d, i) => (i === trackIndex ? data : d)));
    },
    []
  );

  const handleDelete = useCallback((trackIndex: number) => {
    setData(current => current.filter((_, i) => i !== trackIndex));
  }, []);

  const handleAddClick = useCallback(() => {
    setData(current => [...current, undefined]);
  }, []);

  useEffect(() => {
    onChangeRef.current?.(removeUndefined(data));
  }, [data, onChangeRef]);

  return (
    <Forms>
      {data.map((override, i) => (
        <HoobiizOpeningHoursOverrideForm
          key={`${i}-${override?.day}`}
          override={override}
          trackIndex={i}
          disabled={disabled}
          onChange={handleChange}
          onDelete={handleDelete}
        />
      ))}
      <ButtonAsLink onClick={handleAddClick}>
        Ajouter un jour de fermeture exceptionnelle
      </ButtonAsLink>
    </Forms>
  );
};
HoobiizOpeningHoursOverridesForm.displayName = 'HoobiizOpeningHoursOverridesForm';

const Forms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 16px;
  border: solid 1px ${p => p.theme.input.borderColor};
  border-radius: ${p => p.theme.input.borderRadius}px;
`;
