/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import {FC, MouseEvent, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';
import {
  ExpertTicketPaymentStatus,
  ExpertTicketTransactionProductStatusStr,
} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_transaction';

import {apiCall} from '@shared-frontend/api';
import {ButtonAsLink, Link} from '@shared-frontend/components/core/button';
import {showSuccess} from '@shared-frontend/components/core/notifications';

import {
  setExpertTicketDocument,
  setExpertTicketTransaction,
  useAllExpertTicketDocument,
  useAllExpertTicketReservation,
  useAllExpertTicketTransaction,
} from '@src/components/admin/expert_ticket/expert_ticket_admin_store';
import {ExpertTicketProductAndTickets} from '@src/components/admin/expert_ticket/expert_ticket_page';

interface ExpertTicketProductActivityProps {
  product: ExpertTicketProductAndTickets;
}

export const ExpertTicketProductActivity: FC<ExpertTicketProductActivityProps> = props => {
  const {product} = props;

  const reservations = useAllExpertTicketReservation();
  const transactions = useAllExpertTicketTransaction();
  const documents = useAllExpertTicketDocument();

  const productReservations = useMemo(
    () =>
      [...reservations.values()].filter(r =>
        r.Products.map(p => p.ProductId).includes(product.ProductId)
      ),
    [product.ProductId, reservations]
  );
  const productTransactions = useMemo(
    () =>
      [...transactions.values()].filter(r =>
        r.Products.map(p => p.ProductId).includes(product.ProductId)
      ),
    [product.ProductId, transactions]
  );

  const handleCreateTransactionClick = useCallback(
    async (evt: MouseEvent) => {
      const reservationId = evt.currentTarget.getAttribute('data-id') ?? '';
      const reservation = reservations.get(reservationId);
      if (!reservation) {
        return;
      }
      const res = await apiCall(
        HoobiizApi,
        '/admin/expert-ticket-create-transaction',
        {
          products: reservation.Products.map(p => ({
            id: p.ProductId,
            quantity: p.Quantity,
            accessDateTime: p.AccessDateTime,
          })),
          reservationId: reservation.ReservationId,
        },
        {timeout: NO_TIMEOUT}
      );
      if (res.res.Success) {
        setExpertTicketTransaction(res.res.SaleId, res.res);
        setExpertTicketDocument(res.res.SaleId, res.documents);
      }
    },
    [reservations]
  );

  const handleCancellationRequestClick = useCallback(async (evt: MouseEvent) => {
    const saleId = evt.currentTarget.getAttribute('data-id') ?? '';
    const res = await apiCall(
      HoobiizApi,
      '/admin/expert-ticket-cancellation-request',
      {saleId},
      {timeout: NO_TIMEOUT}
    );
    console.log(res.cancellationRequestId);
    showSuccess(`Cancellation request created (${res.cancellationRequestId})`);
  }, []);

  return (
    <Wrapper>
      <Title>Reservations</Title>
      {productReservations.length > 0 ? (
        <Table>
          <THeader>
            <tr>
              <HeaderCell>Id</HeaderCell>
              <HeaderCell>AccessDateTime</HeaderCell>
              <HeaderCell>Products</HeaderCell>
              <HeaderCell>TotalPrice</HeaderCell>
              <HeaderCell>MinutesToExpiry</HeaderCell>
              <HeaderCell>Documents</HeaderCell>
              <HeaderCell>Action</HeaderCell>
            </tr>
          </THeader>
          <tbody>
            {productReservations.map(reservation => {
              const {ReservationId, AccessDateTime, MinutesToExpiry, Products, TotalPrice} =
                reservation;

              return (
                <Row key={ReservationId} onClick={() => console.log(reservation)}>
                  <Cell>{ReservationId}</Cell>
                  <Cell>{AccessDateTime}</Cell>
                  <Cell>
                    {Products.map(p => (
                      <div key={p.ProductId}>{`x${p.Quantity} ${p.ProductName}`}</div>
                    ))}
                  </Cell>
                  <Cell>{TotalPrice}</Cell>
                  <Cell>{MinutesToExpiry}</Cell>
                  <Cell>
                    <ButtonAsLink
                      data-id={ReservationId}
                      onClickAsync={handleCreateTransactionClick}
                    >
                      Create transaction
                    </ButtonAsLink>
                  </Cell>
                </Row>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>No reservations</div>
      )}
      <Title>Transactions</Title>
      {productTransactions.length > 0 ? (
        <Table>
          <THeader>
            <tr>
              <HeaderCell>Id</HeaderCell>
              <HeaderCell>AccessDateTime</HeaderCell>
              <HeaderCell>TransactionDateTime</HeaderCell>
              <HeaderCell>PaymentStatus</HeaderCell>
              <HeaderCell>Ticket Ids</HeaderCell>
              <HeaderCell>Documents</HeaderCell>
              <HeaderCell>Action</HeaderCell>
            </tr>
          </THeader>
          <tbody>
            {productTransactions.map(transaction => {
              const {SaleId, AccessDateTime, Products, PaymentStatus, TransactionDateTime} =
                transaction;
              const docs = documents.get(SaleId) ?? [];

              return (
                <Row key={SaleId} onClick={() => console.log(transaction)}>
                  <Cell>{SaleId}</Cell>
                  <Cell>{AccessDateTime}</Cell>
                  <Cell>{TransactionDateTime}</Cell>
                  <Cell>{`${PaymentStatus} (${ExpertTicketPaymentStatus[PaymentStatus]})`}</Cell>
                  <Cell>
                    {Products.map(p => (
                      <div key={p.ProductId}>{`${
                        ExpertTicketTransactionProductStatusStr[p.Status]
                      } ${p.Tickets.map(t => t.TicketId).join(', ')}`}</div>
                    ))}
                  </Cell>
                  <Cell>
                    {docs.map(doc => (
                      <Link key={doc} href={doc} target="_blank">
                        file
                      </Link>
                    ))}
                  </Cell>
                  <Cell>
                    <ButtonAsLink data-id={SaleId} onClickAsync={handleCancellationRequestClick}>
                      Cancellation request
                    </ButtonAsLink>
                  </Cell>
                </Row>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>No transactions</div>
      )}
    </Wrapper>
  );
};

ExpertTicketProductActivity.displayName = 'ExpertTicketProductActivity';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Table = styled.table`
  width: 100%;
  border: solid 2px ${p => p.theme.main.accentColor};
  border-top: none;
`;
const THeader = styled.thead`
  position: sticky;
  top: 0;
`;
const Row = styled.tr`
  &:nth-child(even) {
    background-color: #00000011;
  }
`;
const Cell = styled.td`
  padding: 2px 4px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 14px;
`;
const HeaderCell = styled.th`
  padding: 4px 8px;
  font-weight: 600;
  background: ${p => p.theme.main.accentColor};
  color: #ffffff;
  vertical-align: middle;
`;
