import {FC} from 'react';
import styled, {useTheme} from 'styled-components';

import {Link} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

export const DocumentLink: FC<{url: string}> = ({url}) => {
  const theme = useTheme();

  return (
    <StyledLink key={url} href={url} target="_blank">
      <SvgIcon name="Download" color={theme.link.textColorActive} size={16} /> Télécharger
    </StyledLink>
  );
};
DocumentLink.displayName = 'DocumentLink';

const StyledLink = styled(Link)`
  gap: 4px;
`;
