import {ReactNode, useCallback} from 'react';
import {useTheme} from 'styled-components';

import {FrontendUserAuthMethodType} from '@shared/dynamo_model';
import {IS_PRODUCTION_ENV} from '@shared/env_constants';

import {ForgotPasswordPage} from '@shared-frontend/components/auth/base/forgot_password_page';
import {LinkPage} from '@shared-frontend/components/auth/base/link_page';
import {LoginPage} from '@shared-frontend/components/auth/base/login_page';
import {LogoutPage} from '@shared-frontend/components/auth/base/logout_page';
import {RegisterInvitePage} from '@shared-frontend/components/auth/base/register_invite_page';
import {RegisterLinkPage} from '@shared-frontend/components/auth/base/register_link_page';
import {RegisterPage} from '@shared-frontend/components/auth/base/register_page';
import {ResetPasswordPage} from '@shared-frontend/components/auth/base/reset_password_page';
import {RouteOpts, useRoute} from '@shared-frontend/components/core/use_route';
import {FrontendUi} from '@shared-frontend/components/frontend_ui';
import {ComponentClass, useComponentClass} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

interface SharedRoutesOpts {
  DisabledComponent: ComponentClass;
}

export function useSharedRoutes(opts: RouteOpts, sharedRoutesOpts: SharedRoutesOpts): ReactNode[] {
  const theme = useTheme();
  const {DisabledComponent} = sharedRoutesOpts;
  const hasPasswordAuth = theme.auth.userAuthTypes.includes(FrontendUserAuthMethodType.Password);
  const whiteLabeling = useSession().whiteLabeling;
  const passwordAuthOnly = useCallback(
    () => (hasPasswordAuth ? undefined : DisabledComponent),
    [DisabledComponent, hasPasswordAuth]
  );

  return [
    // Auth
    useRoute(
      '/login',
      useComponentClass(<LoginPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />),
      {...opts, wrapper: undefined}
    ),
    useRoute(
      '/register-invite',
      useComponentClass(
        <RegisterInvitePage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />
      ),
      {...opts, wrapper: undefined}
    ),
    useRoute(
      '/register',
      useComponentClass(<RegisterPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />),
      {...opts, wrapper: undefined, isDisabledHandler: passwordAuthOnly}
    ),
    useRoute(
      '/forgot-password',
      useComponentClass(
        <ForgotPasswordPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />
      ),
      {...opts, wrapper: undefined, isDisabledHandler: passwordAuthOnly}
    ),
    useRoute(
      '/reset-password/:id',
      useComponentClass(<ResetPasswordPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />),
      {...opts, wrapper: undefined, isDisabledHandler: passwordAuthOnly}
    ),
    useRoute(
      '/logout',
      useComponentClass(<LogoutPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />),
      {...opts, wrapper: undefined}
    ),
    useRoute(
      '/link/:id',
      useComponentClass(<LinkPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />),
      {...opts, wrapper: undefined}
    ),
    useRoute(
      '/register-link/:id',
      useComponentClass(<RegisterLinkPage whiteLabeling={whiteLabeling} wrapper={opts.wrapper} />),
      {...opts, wrapper: undefined}
    ),
    // Other
    useRoute('/ui', FrontendUi, {
      ...opts,
      isDisabledHandler: () => (IS_PRODUCTION_ENV ? DisabledComponent : undefined),
    }),
  ];
}
