import styled from 'styled-components';

export const ItemSticker = styled.div<{$color: string}>`
  padding: 4px 8px;
  background-color: ${p => p.$color};
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  width: max-content;
`;
