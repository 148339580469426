import {FC, Fragment} from 'react';
import styled from 'styled-components';

import {EmailString, FrontendUserAuthMethodType} from '@shared/dynamo_model';

import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {RegisterForm} from '@shared-frontend/components/auth/register_form';
import {RegisterMfaCheckboxes} from '@shared-frontend/components/auth/register_invite_mfa_checkboxes';
import {Button} from '@shared-frontend/components/core/button';
import {QrCode} from '@shared-frontend/components/core/qr_code';
import {ComponentClass, EmptyFragment} from '@shared-frontend/lib/react';

interface RegisterInvitePageModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  email: EmailString;
  totpUri: string | undefined;
  authType: FrontendUserAuthMethodType;
  isAdmin: boolean;
  handleAccessClick: () => void;
  handleMfaRegisterSubmit: () => Promise<void>;
  handleMagicLinkRegisterSubmit: () => Promise<void>;
  handlePasswordRegisterSubmit: (email: EmailString, password: string) => Promise<void>;
}

export const RegisterInvitePageModeDefault: FC<RegisterInvitePageModeDefaultProps> = props => {
  const {
    wrapper,
    email,
    totpUri,
    authType,
    isAdmin,
    handleAccessClick,
    handleMfaRegisterSubmit,
    handleMagicLinkRegisterSubmit,
    handlePasswordRegisterSubmit,
  } = props;

  return (
    <ModeDefaultTemplate wrapper={wrapper}>
      {totpUri !== undefined ? (
        <Content>
          <Title>Votre compte est prêt !</Title>
          <TitleSeparator />
          <div>
            Veuillez enregistrer ce QR Code dans votre application Google Authenticator ou autre
            application de MFA.
          </div>
          <div>Il sera affiché uniquement une fois.</div>
          {IS_BROWSER ? <QrCode totpUri={totpUri} /> : EmptyFragment}
          <Button onClick={handleAccessClick}>Accéder à mon compte !</Button>
        </Content>
      ) : authType === FrontendUserAuthMethodType.MfaCode ? (
        <Fragment>
          <Title>Bienvenue</Title>
          <TitleSeparator />
          <RegisterMfaCheckboxes onSubmit={handleMfaRegisterSubmit} />
        </Fragment>
      ) : authType === FrontendUserAuthMethodType.MagicLink ? (
        <Fragment>
          <Title>Welcome</Title>
          <TitleSeparator />
          <Content>
            <div>
              {/* We are happy to have you among us. An administrator just created your account. */}
              Nous sommes heureux de vous compter parmi nous. Un administrateur vient de créer votre
              compte.
            </div>
            {/* You will receive an email everytime you login with a Magic Link inside. */}
            <div>Vous recevrez un email à chaque connexion contenant un lien magique.</div>
          </Content>
          <ButtonSeparator />
          <Button onClickAsync={handleMagicLinkRegisterSubmit}>Accéder à mon compte !</Button>
        </Fragment>
      ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      authType === FrontendUserAuthMethodType.Password ? (
        <Fragment>
          <Title>{String(isAdmin ? 'Administrateur' : 'Inscription')}</Title>
          <TitleSeparator />
          <RegisterForm onRegisterSubmit={handlePasswordRegisterSubmit} initialEmail={email} />
        </Fragment>
      ) : (
        EmptyFragment
      )}
    </ModeDefaultTemplate>
  );
};

RegisterInvitePageModeDefault.displayName = 'RegisterInvitePageModeDefault';

const TitleSeparator = styled.div`
  height: 20px;
`;

const Content = styled.div`
  font-weight: 300;
  font-size: 34px;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 400;
  color: ${p => p.theme.main.textColor};
`;

const ButtonSeparator = styled.div`
  height: 40px;
`;
