import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {HoobiizWhiteLabelingAuthType} from '@shared/dynamo_model';

import {sharedApiCall} from '@shared-frontend/api';
import {AuthPageProps} from '@shared-frontend/components/auth/base/auth_page_props';
import {RegisterLinkPageModeDefault} from '@shared-frontend/components/auth/mode_default/register_link_page_theme_0';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {RegisterLinkPageModeHero} from '@shared-frontend/components/auth/mode_hero/register_link_page_mode_hero';
import {notifyError} from '@shared-frontend/lib/notification';
import {setSession} from '@shared-frontend/lib/session_store';
import {useParams} from '@shared-frontend/lib/use_typed_params';

export interface RegisterLinkPageProps extends AuthPageProps {
  disabled?: boolean;
}

export const RegisterLinkPage: FC<RegisterLinkPageProps> = props => {
  const {id} = useParams<{id: string}>();
  const {disabled, wrapper, whiteLabeling} = props;
  const {
    main: {api},
    auth: {postLoginPage},
  } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (id === undefined) {
      navigate('/');
    } else {
      sharedApiCall(api, '/register-link', {id})
        .then(data => {
          if (data.success) {
            setSession(data.session);
            navigate(postLoginPage);
          } else {
            navigate('/');
          }
        })
        .catch(err => {
          notifyError(err);
          navigate('/');
        });
    }
  }, [navigate, id, api, postLoginPage, disabled]);

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return <RegisterLinkPageModeHero whiteLabeling={whiteLabelingModeHero} />;
  }
  return <RegisterLinkPageModeDefault wrapper={wrapper} />;
};

RegisterLinkPage.displayName = 'RegisterLinkPage';
