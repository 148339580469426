import {FC} from 'react';

import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {ComponentClass} from '@shared-frontend/lib/react';

interface LogoutModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
}

export const LogoutPageModeDefault: FC<LogoutModeDefaultProps> = ({wrapper}) => {
  return <ModeDefaultTemplate wrapper={wrapper}>À bientôt...</ModeDefaultTemplate>;
};

LogoutPageModeDefault.displayName = 'LogoutPageModeDefault';
