import {FC, FormEventHandler, Fragment} from 'react';
import styled from 'styled-components';

import {neverHappens} from '@shared/lib/type_utils';
import {MfaCode} from '@shared/model/auth/user';

import {LoginFormMode} from '@shared-frontend/components/auth/base/login_page';
import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input, InputProps} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {ComponentClass, EmptyFragment} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface LoginModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  formMode: LoginFormMode;
  emailInputProps: InputProps<string | undefined>;
  passwordInputProps: InputProps<string | undefined>;
  mfaCodeInputProps: InputProps<MfaCode | undefined>;
  isSubmitting: boolean;
  handleEmailSubmit: FormEventHandler<HTMLFormElement>;
  handleLoginSubmit: FormEventHandler<HTMLFormElement>;
}

export const LoginPageModeDefault: FC<LoginModeDefaultProps> = props => {
  const {
    wrapper,
    formMode,
    emailInputProps,
    passwordInputProps,
    mfaCodeInputProps,
    isSubmitting,
    handleEmailSubmit,
    handleLoginSubmit,
  } = props;
  let formContent = EmptyFragment;

  if (formMode === 'email-form' || formMode === 'email-password-form' || formMode === 'mfa-form') {
    formContent = (
      <Fragment>
        <Title>Connexion</Title>
        <Subtitle>Accédez à votre compte</Subtitle>
        <Separator $top={22} $bottom={18} />
        <FormInput>
          <Input {...emailInputProps} />
        </FormInput>
        <Spacing hidden={formMode !== 'email-password-form'} height={12} />
        <FormInput hidden={formMode !== 'email-password-form'}>
          <Input {...passwordInputProps} />
        </FormInput>
        <Spacing hidden={formMode !== 'mfa-form'} height={12} />
        <FormInput hidden={formMode !== 'mfa-form'}>
          <Input {...mfaCodeInputProps} />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <NavLink to="/terms">mentions légales</NavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting}>
          {formMode === 'email-password-form' ? 'Connexion' : 'Continuer'}
        </Button>
        <Spacing height={28} />
        <ForgotPasswordNavLink to="/forgot-password">Mot de passe oublié ?</ForgotPasswordNavLink>
      </Fragment>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (formMode === 'wait-for-magic-link') {
    formContent = (
      <Fragment>
        <Title>Connexion en cours...</Title>
        <Spacing height={24} />
        <Subtitle>
          Nous vous avons envoyé un email contenant un lien. Cliquez sur le lien pour vous
          connecter. Vous pouvez fermer cette page.
        </Subtitle>
      </Fragment>
    );
  } else {
    neverHappens(formMode);
  }

  return (
    <ModeDefaultTemplate
      wrapper={wrapper}
      onSubmit={formMode === 'email-form' ? handleEmailSubmit : handleLoginSubmit}
    >
      {formContent}
    </ModeDefaultTemplate>
  );
};

LoginPageModeDefault.displayName = 'LoginPageModeDefault';

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.main.textColor}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;

const ForgotPasswordNavLink = styled(NavLink)`
  font-size: 80%;
`;
