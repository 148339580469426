import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizTicketFileId} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {createMapStore} from '@shared-frontend/lib/map_data_store';
import {notifyError} from '@shared-frontend/lib/notification';

export const documentUrlsStore = createMapStore<HoobiizTicketFileId, string>();

export function fetchNewDocumentUrls(fileIds: HoobiizTicketFileId[]): void {
  const fileIdsToFetch = fileIds.filter(id => documentUrlsStore.getData(id) === undefined);
  apiCall(HoobiizApi, '/admin/get-files', {ids: fileIdsToFetch})
    .then(({files}) => {
      documentUrlsStore.batchSetData(
        files.map(({id, documentUrl}) => ({key: id, value: documentUrl}))
      );
    })
    .catch(notifyError);
}
