import {FC, ReactNode} from 'react';
import styled from 'styled-components';

import {HoobiizWhiteLabelingAuthType} from '@shared/dynamo_model';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {arrayJoin} from '@shared/lib/array_utils';
import {WhiteLabeling} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {EmptyFragment} from '@shared-frontend/lib/react';

export type ModeHeroAuthWhiteLabeling = WhiteLabeling['auth'] & {
  type: HoobiizWhiteLabelingAuthType.ModeHero;
};

interface ModeHeroTemplateProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
  children: ReactNode;
}

export const ModeHeroInputOverrides: Partial<FrontendTheme['input']> = {
  textColor: '#ffffff',
  textColorDisabled: '#ffffff',
  focusTextColor: '#ffffff',

  backgroundColor: '#024F92',
  backgroundColorHover: '#024F92',
  backgroundColorFocus: '#024F92',
  backgroundColorDisabled: '#024F92',

  borderColor: '#ffffff55',
  hoverBorderColor: '#ffffff99',
  focusBorderColor: '#ffffffcc',
  borderWidth: 2,
  focusBorderWidth: 2,
  focusOutlineColor: 'transparent',
  focusOutlineWidth: 0,
  borderRadius: 8,

  fontSize: 18,
  fontWeight: 500,
  fontFamily: undefined,
  titleMarginBottom: 6,
  paddingRight: 16,
  paddingLeft: 16,
  height: 52,
  labelOpacity: 0.8,
};
export const ModeHeroButtonOverrides: Partial<FrontendTheme['button']> = {};
export const ModeHeroNavLinkOverrides: Partial<FrontendTheme['link']> = {
  fontWeight: 500,
  textColorActive: '#ffffff',
  textColorHover: '#ffffff',
  textColorDisabled: '#ffffff66',
  textColorLoading: '#ffffff66',
  focusBorderColor: '#ffffffcc',
  focusBorderWidth: 3,
};
export const ModeHeroCheckboxOverrides: Partial<FrontendTheme['checkbox']> = {};

export const ModeHeroTemplate: FC<ModeHeroTemplateProps> = props => {
  const {whiteLabeling, children} = props;
  const {hero, logo, title} = whiteLabeling;

  return (
    <Wrapper>
      <Left>
        {logo ? (
          <LogoContainer to="/">
            <HoobiizMediaView
              media={logo}
              cover={false}
              size={{width: '100%', height: '100%'}}
              objectPosition={'right'}
            />
          </LogoContainer>
        ) : (
          EmptyFragment
        )}
        {title !== undefined ? (
          <Title>
            {arrayJoin(
              title.split('\n').map(line => <span key={line}>{line}</span>),
              i => (
                <br key={i} />
              )
            )}
          </Title>
        ) : (
          EmptyFragment
        )}
        {children}
      </Left>
      <Right>
        {hero ? (
          <HoobiizMediaView
            media={hero}
            size={{width: '100%', height: '100%'}}
            cover
            objectPosition={'right'}
          />
        ) : (
          EmptyFragment
        )}
      </Right>
    </Wrapper>
  );
};

const COLUMN_LAYOUT_THRESHOLD_PX = 600;
const COLUMN_LAYOUT_THRESHOLD_VW = COLUMN_LAYOUT_THRESHOLD_PX / 100;
ModeHeroTemplate.displayName = 'ModeHeroTemplate';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #ccc;
  color: #024f92;
  font-weight: 400;
`;
/* eslint-disable @typescript-eslint/no-magic-numbers */
const Left = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: safe center;
  overflow-y: auto;
  background: linear-gradient(to right, #5cdfe5, #004cad);

  flex-shrink: 0;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    flex-shrink: unset;
    flex-grow: 1;
  }

  gap: 6%;
  padding: ${10 * COLUMN_LAYOUT_THRESHOLD_VW}px;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    gap: 10vw;
    padding: 6.4vw;
  }
`;

const Title = styled.div`
  font-weight: bold;
  color: ${p => p.theme.main.accentTextColor};
  text-align: center;
  line-height: 1.5;

  font-size: ${5.5 * COLUMN_LAYOUT_THRESHOLD_VW}px;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    font-size: calc(5.5vw);
  }
`;

const LogoContainer = styled(UnthemedNavLink)`
  width: ${70 * COLUMN_LAYOUT_THRESHOLD_VW}px;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    width: 80vw;
  }
`;
/* eslint-enable @typescript-eslint/no-magic-numbers */

const Right = styled.div`
  flex-grow: 1;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD_PX}px) {
    display: none;
  }
`;
