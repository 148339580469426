import {useCallback} from 'react';
import {styled} from 'styled-components';

import {HoobiizTicketData} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {AgeString} from '@shared-frontend/components/core/timestamp';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {StockHint} from '@src/components/admin/activity_stock/activity_stock_form';
import {FormFlex} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {DocumentLinkFromFile} from '@src/components/ui/document_link_from_file';
import {ItemSticker} from '@src/components/ui/item_sticker';

interface HoobiizTicketDataFormProps {
  data: HoobiizTicketData;
  stockHint?: StockHint;
  isDeleted: boolean;
  onDelete: (data: HoobiizTicketData) => void;
  onRevert: (data: HoobiizTicketData) => void;
  onChange: (oldData: HoobiizTicketData, newData: HoobiizTicketData) => void;
}

export const HoobiizTicketDataForm: Custom<HoobiizTicketDataFormProps, 'div', 'onChange'> = ({
  data,
  stockHint,
  isDeleted,
  onDelete,
  onRevert,
  onChange,
  ...rest
}) => {
  const handleDeleteClick = useCallback(() => onDelete(data), [data, onDelete]);
  const handleRevertClick = useCallback(() => onRevert(data), [data, onRevert]);

  const handleTicketCodeChange = useCallback(
    (newCode: string) => onChange(data, {...data, code: {value: newCode}}),
    [data, onChange]
  );

  const title = data.files !== undefined ? 'Ticket PDF :' : 'Ticket Code :';
  let content = EmptyFragment;
  if (data.code !== undefined) {
    content = (
      <Input
        value={data.code.value}
        syncState={handleTicketCodeChange}
        overrides={adminInputTheme}
        disabled={isDeleted}
      />
    );
  }
  if (data.files !== undefined) {
    content = (
      <div>
        {data.files.map(f => (
          <DocumentLinkFromFile key={f.id} fileId={f.id} />
        ))}
      </div>
    );
  }

  return (
    <Wrapper {...rest} $isDeleted={isDeleted}>
      <Band>
        <SvgIcon name="Ticket" color="#ffffff" size={24} rotate={90} />
      </Band>
      <Content>
        <Title>{title}</Title>
        {content}
      </Content>
      {stockHint !== undefined ? (
        <AgeString ts={stockHint.createdAt} ago lang="fr-FR" />
      ) : (
        <div>Non enregistré</div>
      )}
      {stockHint !== undefined ? (
        stockHint.bought > 0 ? (
          <ItemSticker $color={Colors.Grey}>Acheté</ItemSticker>
        ) : stockHint.reserved > 0 ? (
          <ItemSticker $color={Colors.LightGold}>En attente de paiement</ItemSticker>
        ) : stockHint.remaining > 0 ? (
          <ItemSticker $color={Colors.Green}>Disponible</ItemSticker>
        ) : (
          <ItemSticker $color={Colors.RedLight}>Stock en erreur</ItemSticker>
        )
      ) : (
        <ItemSticker $color={Colors.LightGold}>Nouveau</ItemSticker>
      )}
      {isDeleted ? (
        <DeleteButton onClick={handleRevertClick}>
          <SvgIcon name="Refresh" color="#ffffff" size={24} />
        </DeleteButton>
      ) : (
        <DeleteButton
          onClick={handleDeleteClick}
          disabled={stockHint !== undefined && (stockHint.bought > 0 || stockHint.reserved > 0)}
        >
          <SvgIcon name="Trash" color="#ffffff" size={24} />
        </DeleteButton>
      )}
    </Wrapper>
  );
};
HoobiizTicketDataForm.displayName = 'HoobiizTicketDataForm';

const Wrapper = styled(FormFlex)<{$isDeleted: boolean}>`
  position: relative;
  border: solid 2px ${p => p.theme.main.accentColor};
  border-radius: 8px;
  align-items: center;
  padding: 8px 8px 8px 58px;
  opacity: ${p => (p.$isDeleted ? 1 / 2 : 1)};
`;
const Title = styled.div``;

const Band = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  background-color: ${p => p.theme.main.accentColor};
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(Button)`
  height: 48px;
  width: 48px;
  padding: 0;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
`;
