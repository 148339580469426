import {FC, MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import styled, {useTheme} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiQueryType} from '@shared/api/definitions/search_api';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {randomStringUnsafe} from '@shared/lib/rand';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {AgeString} from '@shared-frontend/components/core/timestamp';
import {hoobiizMediaToImageSrcData} from '@shared-frontend/lib/hoobiiz_media';
import {notifyError} from '@shared-frontend/lib/notification';
import {isMac} from '@shared-frontend/lib/platform';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {
  Table,
  TableHeaderCell,
  TableHeaderText,
  TableImage,
  TableLineCell,
  TableLineCellWithImage,
} from '@src/components/admin/table_fragment';
import {Pagination} from '@src/components/core/pagination';
import {adminInputTheme} from '@src/components/core/theme';
import {HoobiizVisibilityLabels} from '@src/lib/hoobiiz_visibility';

interface VendorTableProps {}

export const VendorTable: FC<VendorTableProps> = () => {
  const navigate = useNavigate();
  const {host} = useSsrContext();
  const theme = useTheme();

  const [vendors, setVendors] = useState([] as FullItem<'HoobiizVendor'>[]);
  const [totalVendors, setTotalVendors] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const loadingToken = useRef('');
  const [loadingParams, setLoadingParams] = useState<
    SearchApiQueryType<'HoobiizVendor', 'full'>['req']
  >({
    table: 'HoobiizVendor',
    gsi: {name: 'updatedAt', desc: true},
    mode: 'full',
    permissions: [],
  });

  // Trigger data fetch when the loadingParams changes
  useEffect(() => {
    const reqLoadingToken = randomStringUnsafe(10);
    setIsLoading(true);
    loadingToken.current = reqLoadingToken;
    apiCall(HoobiizApi, '/admin/search/query', loadingParams)
      .then(res => {
        if (loadingToken.current !== reqLoadingToken) {
          return;
        }
        const {items, total} = res as SearchApiQueryType<'HoobiizVendor', 'full'>['res'];
        setVendors(items.map(i => i.item));
        setTotalVendors(total);
        setIsLoading(false);
      })
      .catch(err => {
        if (loadingToken.current !== reqLoadingToken) {
          return;
        }
        notifyError(err, {message: 'Erreur lors du chargement des partenaires'});
        setIsLoading(false);
      });
  }, [loadingParams]);

  // When clicking a column to change the sorting
  const handleHeaderClick = useCallback<MouseEventHandler>(evt => {
    const sort = evt.currentTarget.getAttribute('data-sort');
    setLoadingParams(loadingParams => ({
      table: 'HoobiizVendor',
      gsi: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: sort as any,
        desc: loadingParams.gsi?.name === sort && !loadingParams.gsi.desc,
      },
      mode: 'full',
      permissions: [],
    }));
  }, []);

  // When clicking a line to open a vendor
  const handleLineClick = useCallback<MouseEventHandler>(
    evt => {
      const id = evt.currentTarget.getAttribute('data-id');
      const openInNewTab = (isMac() && evt.metaKey) || (!isMac() && evt.ctrlKey);
      const path = `/admin/vendor/${id}`;
      if (openInNewTab) {
        window.open(`${window.location.origin}${path}`);
      } else {
        navigate(path);
      }
    },
    [navigate]
  );

  // Utility to generate the sort icon on the column headers
  const sortIcon = useCallback(
    (sortName: string) => {
      const {name, desc} = loadingParams.gsi ?? {};
      if (name !== sortName) {
        return EmptyFragment;
      }
      return (
        <SvgIcon
          name={desc ? 'ChevronUp' : 'ChevronDown'}
          size={12}
          color={theme.main.accentTextColor}
        />
      );
    },
    [loadingParams.gsi, theme.main.accentTextColor]
  );

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Wrapper>
      <Input
        placeholder="Filtrer"
        value={searchQuery}
        syncState={setSearchQuery}
        overrides={adminInputTheme}
        width="100%"
      />
      <Pagination>
        {totalVendors === undefined
          ? ''
          : `${totalVendors.toLocaleString()} partenaires dans le système`}
      </Pagination>
      <Table $isLoading={isLoading}>
        {/* HEADER */}
        <thead>
          <tr>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'name'}>
                <TableHeaderText>Partenaire</TableHeaderText>
                {sortIcon('name')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'createdAt'}>
                <TableHeaderText>Création</TableHeaderText>
                {sortIcon('createdAt')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'updatedAt'}>
                <TableHeaderText>Dernière modification</TableHeaderText>
                {sortIcon('updatedAt')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'visibility'}>
                <TableHeaderText>Status</TableHeaderText>
                {sortIcon('visibility')}
              </TableHeaderCell>
            </th>
          </tr>
        </thead>
        {/* BODY */}
        <tbody>
          {vendors
            .filter(vendor =>
              searchQuery.length === 0
                ? true
                : // Includes vendors where name matches the search query
                  vendor.name.toLocaleUpperCase().includes(searchQuery.toLocaleUpperCase())
            )
            .map(vendor => {
              const logo = vendor.logo;
              return (
                <tr key={vendor.id} onClick={handleLineClick} data-id={vendor.id}>
                  <td>
                    <TableLineCellWithImage $noWrapp>
                      {logo ? (
                        <TableImage
                          alt={''}
                          srcAndSizes={{srcData: hoobiizMediaToImageSrcData(host, logo), width: 64}}
                          rawUrls
                          width={64}
                          height={64}
                        />
                      ) : (
                        EmptyFragment
                      )}
                      {vendor.name}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCell $noWrapp>
                      <AgeString ts={vendor.createdAt} ago lang="fr-FR" />
                    </TableLineCell>
                  </td>
                  <td>
                    <TableLineCell $noWrapp>
                      <AgeString ts={vendor.updatedAt} ago lang="fr-FR" />
                    </TableLineCell>
                  </td>
                  <td>
                    <TableLineCell $noWrapp>
                      {HoobiizVisibilityLabels[vendor.visibility]}
                    </TableLineCell>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Wrapper>
  );
};
VendorTable.displayName = 'VendorTable';

const Wrapper = styled.div``;
