import {FC, ReactNode} from 'react';

import {HoobiizUserGroupId} from '@shared/dynamo_model';

import {AdminApp} from '@shared-frontend/components/admin/admin_app';
import {useParams} from '@shared-frontend/lib/use_typed_params';

interface CseAdminAppWrapperProps {
  children?: ReactNode;
}

export const CseAdminAppWrapper: FC<CseAdminAppWrapperProps> = props => {
  const {children} = props;
  const {groupId} = useParams<{groupId: HoobiizUserGroupId}>();
  return (
    <AdminApp
      nav={[
        {
          path: `/cse-admin/${groupId}`,
          label: 'Bénéficiaires',
          otherPaths: [`/cse-admin/${groupId}/users`],
        },
      ]}
    >
      {children}
    </AdminApp>
  );
};

CseAdminAppWrapper.displayName = 'CseAdminAppWrapper';
