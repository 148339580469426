import {FC} from 'react';
import styled from 'styled-components';

import {keys} from '@shared/lib/map_utils';

import {ICONS, SvgIcon} from '@shared-frontend/components/core/svg_icon';

export const IconsPage: FC = () => {
  return (
    <Container>
      {keys(ICONS).map(name => (
        <SvgIcon key={name} name={name} />
      ))}
    </Container>
  );
};

IconsPage.displayName = 'IconsPage';

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
