import {FC, useCallback} from 'react';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';

import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {ComponentClass} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface ResetPasswordPageModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  email: EmailString;
  setEmail: (email: EmailString) => void;
  password: string;
  setPassword: (password: string) => void;
  handlePasswordResetSubmit: (email: EmailString, password: string) => void;
  isSubmitting: boolean;
}

export const ResetPasswordPageModeDefault: FC<ResetPasswordPageModeDefaultProps> = props => {
  const {wrapper, email, setEmail, password, setPassword, handlePasswordResetSubmit, isSubmitting} =
    props;
  const handleFormSubmit = useCallback(() => {
    handlePasswordResetSubmit(email, password);
  }, [email, password, handlePasswordResetSubmit]);

  return (
    <ModeDefaultTemplate wrapper={wrapper} onSubmit={handleFormSubmit}>
      <Title>Mot de passe oublié ?</Title>
      <Subtitle>Modifiez votre mot de passe</Subtitle>
      <Separator $top={22} $bottom={18} />
      <FormInput>
        <Input
          width="100%"
          type="email"
          value={email}
          syncState={setEmail}
          placeholder="jean@exemple.com"
          label="ADRESSE EMAIL"
          autoComplete="email"
          autoFocus
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          type="password"
          value={password}
          syncState={setPassword}
          label="NOUVEAU MOT DE PASSE"
          autoComplete="new-password"
        />
      </FormInput>
      <Spacing height={28} />
      <LegalText>
        En continuant, vous reconnaissez avoir lu les{' '}
        <NavLink to="/terms">mentions légales</NavLink>
      </LegalText>
      <Spacing height={28} />
      <Button expand submit disabled={isSubmitting}>
        Modifier le mot de passe
      </Button>
    </ModeDefaultTemplate>
  );
};

ResetPasswordPageModeDefault.displayName = 'ResetPasswordPageModeDefault';

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.main.textColor}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;
