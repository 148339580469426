import {FC, ReactNode, useCallback, useRef, useState} from 'react';
import styled from 'styled-components';

import {NULL_REF} from '@shared-frontend/lib/react';
import {useResizeObserver} from '@shared-frontend/lib/use_resize_observer';

interface AutoScaleProps {
  width: number;
  height: number;
  children: ReactNode;
}

export const AutoScale: FC<AutoScaleProps> = props => {
  const {width, height, children} = props;
  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);
  const aspectRatio = width / height;

  const [wrapperSize, setWrapperSize] = useState<{width: number; height: number}>();
  const handleWrapperResize = useCallback((entry: ResizeObserverEntry): void => {
    setWrapperSize({width: entry.contentRect.width, height: entry.contentRect.height});
  }, []);
  useResizeObserver(wrapperRef, handleWrapperResize);
  const scale = wrapperSize ? wrapperSize.width / width : undefined;
  return (
    <Wrapper ref={wrapperRef} $aspectRatio={aspectRatio}>
      <Inner $width={width} $height={height} $scale={scale}>
        {children}
      </Inner>
    </Wrapper>
  );
};

AutoScale.displayName = 'AutoScale';

const Wrapper = styled.div<{$aspectRatio: number}>`
  position: relative;
  width: 100%;
  aspect-ratio: ${p => p.$aspectRatio};
  overflow: hidden;
`;

const Inner = styled.div<{$width: number; $height: number; $scale?: number}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  transform: scale(${p => p.$scale ?? 1});
  transform-origin: top left;
`;
