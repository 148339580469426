import {AccountDropdown} from './account_dropdown';
import {CartNavLink} from './cart_nav_link';
import {FC, Fragment, useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {AppLogo} from '@shared-frontend/components/auth/app_logo';
import {NavLink, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {Colors, Sizes} from '@src/components/core/theme_base';
import {SearchBar} from '@src/components/ui/search_bar';
import {useCartCount, useHoobiizSession} from '@src/lib/stores';
import {useNavHeight} from '@src/lib/use_nav_height';
import {ZINDEX} from '@src/lib/zindex';

export const Navigation: FC = () => {
  const session = useHoobiizSession();
  const location = useLocation();
  const theme = useTheme();
  const {isDesktop} = useResponsive();
  const {headerHeight} = useNavHeight();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const cartCount = useCartCount();

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(prev => !prev);
  }, []);

  const toggleMobileSearch = useCallback(() => {
    setIsMobileSearchOpen(prev => !prev);
  }, []);

  const preventClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  if (['/404', '/devenir-partenaire', '/youpiiz-cest-quoi'].includes(location.pathname)) {
    return EmptyFragment;
  }

  return (
    <Wrapper hidden={location.pathname === '/404'} $headerHeight={headerHeight}>
      <Container $isDesktop={isDesktop}>
        {!isDesktop ? (
          <Fragment>
            {isMobileMenuOpen ? (
              <MobileMenu onClick={toggleMobileMenu}>
                <MobileMenuLeft onClick={preventClick}>
                  <MobileMenuTop>
                    <MenuTitle>Menu</MenuTitle>
                    <ClickableSvgIcon name="Close" onClick={toggleMobileMenu} height={20} />
                  </MobileMenuTop>
                  {session === undefined ? (
                    <Fragment>
                      <NavLink to="/login">Connexion</NavLink>
                      <NavLink to="/register">Inscription</NavLink>
                      <NavLink to="/contact">Contact</NavLink>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <NavLink to="/cart">{`Mon panier${
                        cartCount === undefined ? '' : ` (${cartCount})`
                      }`}</NavLink>
                      <NavLink to="/orders">Mes commandes</NavLink>
                      <NavLink to="/account">Mon compte</NavLink>
                      <NavLink to="/logout">Déconnexion</NavLink>
                    </Fragment>
                  )}
                </MobileMenuLeft>
              </MobileMenu>
            ) : (
              EmptyFragment
            )}
            {isMobileSearchOpen ? (
              <MobileMenu onClick={toggleMobileSearch}>
                <MobileMenuLeft onClick={preventClick} $fullWidth>
                  <MobileMenuTop>
                    <MenuTitle>Recherche</MenuTitle>
                    <ClickableSvgIcon name="Close" onClick={toggleMobileSearch} height={20} />
                  </MobileMenuTop>
                  <StyledSearchBar />
                </MobileMenuLeft>
              </MobileMenu>
            ) : (
              EmptyFragment
            )}
            <ClickableSvgIcon name="Menu" onClick={toggleMobileMenu} />
            <ClickableSvgIcon name="Search" onClick={toggleMobileSearch} />
          </Fragment>
        ) : (
          EmptyFragment
        )}
        <Brand to="/">
          <AppLogo logo={theme.main.logo} />
        </Brand>
        {!isDesktop ? (
          <Fragment>
            <NavLink to="/cart">
              <ClickableSvgIcon name="Cart" />
            </NavLink>
            <NavLink to="/account">
              <ClickableSvgIcon name="UserCircled" />
            </NavLink>
          </Fragment>
        ) : (
          <Fragment>
            <StyledSearchBar />
            <Right>
              {session === undefined ? (
                <Fragment>
                  <NavLink to="/login">Connexion</NavLink>
                  <NavLink to="/register">Inscription</NavLink>
                  <NavLink to="/contact">Contact</NavLink>
                </Fragment>
              ) : (
                <Fragment>
                  <CartNavLink />
                  <AccountDropdown />
                </Fragment>
              )}
            </Right>
          </Fragment>
        )}
      </Container>
    </Wrapper>
  );
};
Navigation.displayName = 'Navigation';

const Wrapper = styled.nav<{$headerHeight: number}>`
  width: 100%;
  height: ${({$headerHeight}) => `${$headerHeight}px`};
  z-index: ${ZINDEX.NavBar};
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: ${Colors.White};
`;

const Container = styled.div<{$isDesktop: boolean}>`
  width: 100%;
  max-width: ${Sizes.Content};
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({$isDesktop}) => ($isDesktop ? '0 32px' : '0 12px')};
  gap: ${({$isDesktop}) => ($isDesktop ? '32px' : '12px')};
`;

const Brand = styled(UnthemedNavLink)<{$isDesktop: boolean}>`
  flex-shrink: 0;
  height: ${({$isDesktop}) => ($isDesktop ? '64px' : '40px')};
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledSearchBar = styled(SearchBar)`
  flex-grow: 1;
`;

const ClickableSvgIcon = styled(SvgIcon)`
  cursor: pointer;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  z-index: ${ZINDEX.NavBar};
`;

const MobileMenuLeft = styled.div<{$fullWidth?: boolean}>`
  width: ${({$fullWidth}) => ($fullWidth ? '100%' : '80%')};
  max-width: 450px;
  height: 100vh;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  padding: 28px;
  gap: 28px;
`;

const MobileMenuTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MenuTitle = styled.div`
  font-size: 24px;
`;
