import {FC, MouseEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {HoobiizActivityId, HoobiizStockReservationType} from '@shared/dynamo_model';
import {franceToUtc, startOfFranceDay} from '@shared/lib/date_utils';
import {padNumber} from '@shared/lib/format_utils';
import {getStocksAndOffersAtDate} from '@shared/lib/hoobiiz/stock';

import {apiCall} from '@shared-frontend/api';
import {Calendar} from '@shared-frontend/components/core/calendar';
import {hideModal, showRawModal} from '@shared-frontend/components/core/modal';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {
  useNumberQueryString,
  useOptionalStringQueryString,
} from '@shared-frontend/lib/use_query_string';

import {HoobiizAdminStockCalendarModal} from '@src/components/admin/activity_stock/hoobiiz_admin_stock_calendar_modal';

interface HoobiizAdminStockCalendarProps {
  activityId: HoobiizActivityId;
}

export const formatDate = (date: Date): string =>
  [date.getFullYear(), padNumber(date.getMonth() + 1, 2), padNumber(date.getDate(), 2)].join('-');

export const parseDate = (dateStr: string): Date | undefined => {
  const [year, month, day] = dateStr.split('-');
  if (year === undefined || month === undefined || day === undefined) {
    return;
  }
  return new Date(parseFloat(year), parseFloat(month) - 1, parseFloat(day));
};

export const HoobiizAdminStockCalendar: FC<HoobiizAdminStockCalendarProps> = props => {
  const {activityId} = props;

  // Month/year handling

  const [year, setYear] = useNumberQueryString('year', new Date().getFullYear());
  const [month, setMonth] = useNumberQueryString('month', new Date().getMonth() + 1);
  const [date, setDate] = useOptionalStringQueryString('date');

  const handlePreviousMonthClick = useCallback(() => {
    const newDate = new Date(year, month - 1);
    newDate.setMonth(newDate.getMonth() - 1);
    setMonth(newDate.getMonth() + 1);
    setYear(newDate.getFullYear());
  }, [month, setMonth, setYear, year]);

  const handleNextMonthClick = useCallback(() => {
    const newDate = new Date(year, month - 1);
    newDate.setMonth(newDate.getMonth() + 1);
    setMonth(newDate.getMonth() + 1);
    setYear(newDate.getFullYear());
  }, [month, setMonth, setYear, year]);

  useEffect(() => {
    return () => hideModal();
  }, []);

  // Data and stock at date

  const [data, setData] = useState<ApiDef<typeof HoobiizApi>['/admin/activity-stocks']['res']>();
  useMemo(() => {
    setData(undefined);
    apiCall(HoobiizApi, '/admin/activity-stocks', {
      activityId,
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      startTs: franceToUtc(new Date(Date.UTC(year, month - 1, -7))).getTime(),
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      endTs: franceToUtc(new Date(year, month, 7)).getTime(),
    })
      .then(setData)
      .catch(notifyError);
  }, [activityId, month, year]);

  // Handle clicking on calendar cell

  const handleDayClick = useCallback<MouseEventHandler>(
    evt => {
      const ts = parseFloat(evt.currentTarget.getAttribute('data-ts') ?? '0');
      setDate(formatDate(new Date(ts)));
    },
    [setDate]
  );

  // Trigger modal
  useEffect(() => {
    if (date === undefined) {
      hideModal();
      return;
    }
    showRawModal({
      mode: 'slide-down',
      children: <HoobiizAdminStockCalendarModal activityId={activityId} />,
      onHide: () => {
        setDate(undefined);
      },
      noAutoHide: true,
    });
  }, [activityId, date, setDate]);

  // Cell rendering

  const renderCell = useCallback(
    (date: Date) => {
      const startOfFrDate = startOfFranceDay();
      // const past = frDate.getTime() < startOfFrDate.getTime();
      const today = date.getTime() === startOfFrDate.getTime();

      let content = (
        <CalendarCellContent>
          <Loading>Chargement...</Loading>
        </CalendarCellContent>
      );
      if (data) {
        const stocks = getStocksAndOffersAtDate(data.stocks, date).filter(
          s => s.stock.reservation.type === HoobiizStockReservationType.Fixed
        );
        let total = 0;
        let remaining = 0;
        let bought = 0;
        let reserved = 0;
        for (const s of stocks) {
          total += s.stock.quantity;
          remaining += s.stock.remaining;
          bought += s.stock.bought;
          reserved += s.stock.reserved;
        }
        content =
          total === 0 ? (
            <CalendarCellContent>-</CalendarCellContent>
          ) : (
            <CalendarCellContent>
              <div>{`${remaining.toLocaleString()} / ${total.toLocaleString()}`}</div>
              <div>{`(${bought.toLocaleString()} acheté${bought === 1 ? '' : 's'})`}</div>
              {reserved > 0 ? (
                <div>{`(${reserved.toLocaleString()} en cours${reserved === 1 ? '' : 's'})`}</div>
              ) : (
                EmptyFragment
              )}
            </CalendarCellContent>
          );
      }

      return (
        <CalendarCell
          // $past={!today && date.getTime() < startOfFrDay.getTime()}
          key={date.getTime()}
          data-ts={date.getTime()}
          onClick={handleDayClick}
        >
          <CalendarCellDay>
            <CalendarCellDayNumber $today={today}>{date.getDate()}</CalendarCellDayNumber>
          </CalendarCellDay>
          {content}
        </CalendarCell>
      );
    },
    [data, handleDayClick]
  );

  return (
    <Wrapper>
      <StyledCalendar
        month={month - 1}
        year={year}
        onPreviousClick={handlePreviousMonthClick}
        onNextClick={handleNextMonthClick}
        renderCell={renderCell}
      />
    </Wrapper>
  );
};

HoobiizAdminStockCalendar.displayName = 'HoobiizAdminStockCalendar';

const Wrapper = styled.div``;

const StyledCalendar = styled(Calendar)`
  margin: auto;
  overflow: visible;
  & > tbody {
    border-bottom: solid 2px ${p => p.theme.main.accentColor};
    border-right: solid 2px ${p => p.theme.main.accentColor};
  }
`;

const columns = 7;
const extraSpace = 248; // 200px menu + 24px padding x2
const borderSize = 2;
const width = `calc(${100 / columns}vw - ${
  extraSpace / columns + borderSize + borderSize / columns
}px)`;
const maxWidth = `140px`;

const CalendarCell = styled.div`
  width: ${width};
  max-width: ${maxWidth};
  height: ${width};
  max-height: ${maxWidth};
  display: flex;
  flex-direction: column;
  border-top: solid 2px ${p => p.theme.main.accentColor};
  border-left: solid 2px ${p => p.theme.main.accentColor};
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.main.accentColor}22;
  }
`;

const CalendarCellDay = styled.div`
  margin-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 1.5vw;
  @media (min-width: 1250px) {
    font-size: 18px;
  }
`;
const CalendarCellDayNumber = styled.div<{
  $today: boolean;
}>`
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${p => (p.$today ? p => p.theme.main.accentColor : '#f3f3f3')};
  color: ${p => (p.$today ? '#ffffff' : '#787777')};
  @media (min-width: 1250px) {
    width: 36px;
    height: 36px;
  }
`;

const CalendarCellContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  font-size: 1.5vw;
  @media (min-width: 1250px) {
    font-size: 18px;
  }
`;

const Loading = styled.div`
  color: #888;
  font-size: 1.5vw;
  @media (min-width: 1250px) {
    font-size: 18px;
  }
`;
