import {FC} from 'react';
import styled from 'styled-components';

import {SanitizedItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

interface HoobiizCat1ViewProps {
  name: string;
  media?: SanitizedItem<'HoobiizMedia'>;
  to: string;
}

export const HoobiizCat1View: FC<HoobiizCat1ViewProps> = ({name, media, to}) => {
  const {isMobile} = useResponsive();

  return (
    <StyledNavLink to={to}>
      <Wrapper $isMobile={isMobile}>
        <HoobiizMediaView media={media} />
        <Title>{name}</Title>
      </Wrapper>
    </StyledNavLink>
  );
};

HoobiizCat1View.displayName = 'HoobiizCat1View';

const StyledNavLink = styled(UnthemedNavLink)`
  width: 100%;
`;

const Wrapper = styled.div<{$isMobile: boolean}>`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  max-width: 300px;

  img {
    transition: transform 200ms ease-in-out;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  ${({$isMobile}) => ($isMobile ? 'max-height: 100px;' : '')}
`;

const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  text-align: center;

  font-size: 24px;
  font-weight: 700;
  padding: 50px 10px 28px 10px;
  color: #ffffff;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
`;
