import {FC, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {ROOT_USER_GROUP_ID} from '@shared/model/hoobiiz/hoobiiz_common';

import {hideModal, showRawModal} from '@shared-frontend/components/core/modal';

import {AdminUserAndGroupModal} from '@src/components/admin/user_and_group/admin_user_and_group_modal';
import {
  getHoobiizUserGroupRootPath,
  useHoobiizUserGroupPath,
} from '@src/components/admin/user_and_group/admin_user_and_group_paths';
import {AdminUserAndGroupTree} from '@src/components/admin/user_and_group/admin_user_and_group_tree';
import {AdminUserAndGroupUserModal} from '@src/components/admin/user_and_group/admin_user_and_group_user_modal';
import {AdminUserAndGroupWhiteLabelingPage} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_page';

interface AdminUserAndGroupPageProps {}

export const AdminUserAndGroupPage: FC<AdminUserAndGroupPageProps> = () => {
  const navigate = useNavigate();

  const navigteToRoot = useCallback(() => {
    navigate(getHoobiizUserGroupRootPath());
  }, [navigate]);

  const {groupIds, groupModal, userModal, theme} = useHoobiizUserGroupPath();
  useEffect(() => {
    // Show GROUP modal
    if (groupModal && !theme) {
      showRawModal({
        mode: 'slide-down',
        children: <AdminUserAndGroupModal groupId={groupModal} />,
        onHide: navigteToRoot,
        noAutoHide: true,
      });
    }
    // Show USER modal
    else if (userModal && !theme) {
      showRawModal({
        mode: 'slide-down',
        children: <AdminUserAndGroupUserModal userId={userModal} />,
        onHide: navigteToRoot,
        noAutoHide: true,
      });
    }
    // Hide modal
    else {
      hideModal({noCallback: true});
    }
  }, [groupIds, groupModal, navigate, navigteToRoot, theme, userModal]);

  if (groupModal && theme) {
    return <AdminUserAndGroupWhiteLabelingPage groupId={groupModal} groupIds={groupIds} />;
  }
  return <Wrapper>{<AdminUserAndGroupTree groupId={ROOT_USER_GROUP_ID} />}</Wrapper>;
};

AdminUserAndGroupPage.displayName = 'AdminUserAndGroupPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
`;
