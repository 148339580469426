import {FC, Fragment} from 'react';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';
import {neverHappens} from '@shared/lib/type_utils';

import {ForgotPasswordFormMode} from '@shared-frontend/components/auth/base/forgot_password_page';
import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {ComponentClass, EmptyFragment} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface ForgotPasswordPageModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  email: EmailString;
  setEmail: (email: EmailString) => void;
  formMode: ForgotPasswordFormMode;
  handleEmailSubmit: () => void;
  isSubmitting: boolean;
}

export const ForgotPasswordPageModeDefault: FC<ForgotPasswordPageModeDefaultProps> = props => {
  const {wrapper, email, setEmail, formMode, handleEmailSubmit, isSubmitting} = props;

  let formContent = EmptyFragment;
  if (formMode === 'initial') {
    formContent = (
      <Fragment>
        <Title>Mot de passe oublié ?</Title>
        <Subtitle>Réinitialisez votre mot de passe</Subtitle>
        <Separator $top={22} $bottom={18} />
        <FormInput>
          <Input
            width="100%"
            type="email"
            value={email}
            syncState={setEmail}
            placeholder="jean@exemple.com"
            label="ADRESSE EMAIL"
            autoComplete="email"
            autoFocus
          />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <NavLink to="/terms">mentions légales</NavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting}>
          Recevoir un lien par email
        </Button>
      </Fragment>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (formMode === 'finish') {
    formContent = (
      <Fragment>
        <Title>Lien envoyé</Title>
        <Spacing height={24} />
        <Subtitle>
          Nous vous avons envoyé un email contenant un lien. Cliquez sur le lien pour modifier votre
          mot de passe. Vous pouvez fermer cette page.
        </Subtitle>
      </Fragment>
    );
  } else {
    neverHappens(formMode);
  }

  return (
    <ModeDefaultTemplate wrapper={wrapper} onSubmit={handleEmailSubmit}>
      {formContent}
    </ModeDefaultTemplate>
  );
};

ForgotPasswordPageModeDefault.displayName = 'ForgotPasswordPageModeDefault';

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.main.textColor}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;
