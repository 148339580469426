import styled from 'styled-components';

import {HoobiizPromoCodeStock} from '@shared/dynamo_model';

import {lighten} from '@shared-frontend/colors';
import {UnthemedButton} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Custom} from '@shared-frontend/lib/react';

import {HoobiizIconSvg} from '@src/components/ui/hoobiiz_icon_svg';

interface HoobiizReservationCtaProps {
  bestStock?: {
    hasFixedStocks?: boolean;
    hasFlexibleStocks?: boolean;
  };
  promoCodeStock: HoobiizPromoCodeStock | undefined;
}

export const HoobiizReservationCta: Custom<HoobiizReservationCtaProps, 'button'> = props => {
  const {bestStock, promoCodeStock, ...rest} = props;

  if (!bestStock?.hasFlexibleStocks && !bestStock?.hasFixedStocks && !promoCodeStock) {
    return;
  }

  return (
    <Wrapper {...rest}>
      <Icon height={32} />
      {bestStock?.hasFlexibleStocks || promoCodeStock ? 'Acheter' : 'Réserver'}
      <SvgIcon name="TriangleDown" color="#ffffff" size={12} />
    </Wrapper>
  );
};

HoobiizReservationCta.displayName = 'HoobiizReservationCta';

/* eslint-disable @typescript-eslint/no-magic-numbers */
const Wrapper = styled(UnthemedButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(
    197deg,
    ${p => lighten(p.theme.main.accentColor, 0.14)} 0%,
    ${p => lighten(p.theme.main.accentColor, 0.14)} 50%,
    ${p => lighten(p.theme.main.accentColor, 0.64)} 100%
  );
  color: white;
  font-weight: 500;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 14px;

  transition: box-shadow 120ms ease-in-out;
  &:hover {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
  }
`;

const Icon = styled(HoobiizIconSvg)`
  margin-top: 4px;
`;
/* eslint-enable @typescript-eslint/no-magic-numbers */
