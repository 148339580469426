import {FC} from 'react';
import styled from 'styled-components';

import {lighten} from '@shared-frontend/colors';
import {NavLink, UnthemedButton} from '@shared-frontend/components/core/button';
import {Dropdown} from '@shared-frontend/components/core/dropdown';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {useCartCount} from '@src/lib/stores';

interface AccountDropdownProps {}

export const AccountDropdown: FC<AccountDropdownProps> = () => {
  const cartCount = useCartCount();

  return (
    <Dropdown
      button={
        <AccountMenuButton>
          <SvgIcon name="UserCircled" height={30} color="#ffffff" />
          <SvgIcon name="TriangleDown" color="#ffffff" size={10} />
        </AccountMenuButton>
      }
      content={
        <AccountMenu>
          <AccountMenuLink to="/cart">{`Mon panier${
            cartCount === undefined ? '' : ` (${cartCount})`
          }`}</AccountMenuLink>
          <AccountMenuLink to="/orders">Mes commandes</AccountMenuLink>
          <AccountMenuLink to="/account">Mon compte</AccountMenuLink>
          <AccountMenuLink to="/logout">Déconnexion</AccountMenuLink>
        </AccountMenu>
      }
      align="right"
      ariaLabel="account dropdown"
    />
  );
};

AccountDropdown.displayName = 'AccountDropdown';

/* eslint-disable @typescript-eslint/no-magic-numbers */
const AccountMenuButton = styled(UnthemedButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 6px;

  background: linear-gradient(
    197deg,
    ${p => lighten(p.theme.main.accentColor, 0.14)} 0%,
    ${p => lighten(p.theme.main.accentColor, 0.14)} 50%,
    ${p => lighten(p.theme.main.accentColor, 0.64)} 100%
  );

  transition: box-shadow 120ms ease-in-out;
  &:hover {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
  }
`;
/* eslint-enable @typescript-eslint/no-magic-numbers */

const AccountMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-width: 200px;
  border-radius: 8px;
  padding: 8px 0px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
`;

const AccountMenuLink = styled(NavLink)`
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  &:hover {
    background-color: #00000008;
  }
`;
