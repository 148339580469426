import {useMemo} from 'react';
import {styled} from 'styled-components';

import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {SanitizedItem} from '@shared/model/search_tables';

import {Image, ImageDimension, ImageFullProps} from '@shared-frontend/components/core/image';
import {hoobiizMediaToImageProps} from '@shared-frontend/lib/hoobiiz_media';
import {Custom} from '@shared-frontend/lib/react';

interface HoobiizMediaViewProps {
  media?: SanitizedItem<'HoobiizMedia'>;
  size?: ImageDimension;
  radius?: number;
}

// TODO: @matthis - Omit CSS width/height attributes from props, we use size={{width/height}} instead
export const HoobiizMediaView: Custom<
  HoobiizMediaViewProps & Partial<ImageFullProps>,
  'img'
> = props => {
  const {media, size = {width: '100%', height: '100%'}, radius, className, ...extraProps} = props;
  const {host} = useSsrContext();

  const imageProps = useMemo<ImageFullProps>(
    () => hoobiizMediaToImageProps(host, media, size),
    [media, host, size]
  );

  return (
    <StyledImage
      {...imageProps}
      lazyLoading
      cover
      $radius={radius}
      pictureProps={{className}}
      {...extraProps}
    />
  );
};

HoobiizMediaView.displayName = 'HoobiizImageView';

const StyledImage = styled(Image)<{$radius?: number}>`
  ${p => (p.$radius === undefined ? false : `overflow: hidden; border-radius: ${p.$radius}px`)}
`;
