import {useMemo} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FrontendUserId} from '@shared/dynamo_model';
import {HoobiizUser} from '@shared/model/hoobiiz/hoobiiz_users';

import {apiCall} from '@shared-frontend/api';
import {createDataStore} from '@shared-frontend/lib/data_store';
import {createMapStore} from '@shared-frontend/lib/map_data_store';
import {notifyError} from '@shared-frontend/lib/notification';

interface UserStoreState {
  isLoaded: boolean;
  isLoading: boolean;
}

const userStoreState = createDataStore<UserStoreState>({
  isLoaded: false,
  isLoading: false,
});
export const useUserStoreState = userStoreState.useData;
export const getUserStoreState = userStoreState.getData;
const setUserStoreState = userStoreState.setData;

const userStore = createMapStore<FrontendUserId, HoobiizUser>();
export const getUserGroup = userStore.getData;
export const setUserGroup = userStore.setData;
const batchSetUserGroup = userStore.batchSetData;

export function loadAllUsers(): void {
  // Mark as loading
  setUserStoreState({...getUserStoreState(), isLoading: true});

  // Fetch all users
  apiCall(HoobiizApi, '/admin/list-all-users', {})
    .then(({items}) => {
      // Update store
      batchSetUserGroup(items.map(item => ({key: item.userId, value: item})));
      // Mark as loaded
      setUserStoreState({...getUserStoreState(), isLoading: false, isLoaded: true});
    })
    .catch(err => {
      notifyError(err, {message: 'Échec de la récupération des utilisateurs'});
      setUserStoreState({...getUserStoreState(), isLoading: false});
    });
}

export function useAllUsers(): HoobiizUser[] | undefined {
  const {isLoaded, isLoading} = useUserStoreState();
  const allUsers = userStore.useAllData();
  return useMemo(() => {
    if (!isLoaded || isLoading) {
      return undefined;
    }
    return [...allUsers.values()];
  }, [isLoaded, isLoading, allUsers]);
}

export function getAllUsers(): HoobiizUser[] | undefined {
  const {isLoaded, isLoading} = getUserStoreState();
  const allUsers = userStore.getAllData();
  if (!isLoaded || isLoading) {
    return undefined;
  }
  return [...allUsers.values()];
}
