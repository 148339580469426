import {FC} from 'react';
import styled from 'styled-components';

import {
  ModeHeroAuthWhiteLabeling,
  ModeHeroTemplate,
} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';

interface LogoutPageModeHeroProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
}

export const LogoutPageModeHero: FC<LogoutPageModeHeroProps> = props => {
  const {whiteLabeling} = props;

  return (
    <ModeHeroTemplate whiteLabeling={whiteLabeling}>
      <Wrapper>À bientôt...</Wrapper>
    </ModeHeroTemplate>
  );
};

LogoutPageModeHero.displayName = 'LogoutPageModeHero';

const Wrapper = styled.div`
  color: ${p => p.theme.main.accentTextColor};
  line-height: 1.5;
`;
