export function formatHoobiizDate(d: Date | undefined): string {
  if (d === undefined) {
    return '';
  }
  return d
    .toLocaleDateString('fr', {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .toUpperCase();
}

export function formatFullHoobiizDate(d: Date | undefined): string {
  if (d === undefined) {
    return '';
  }
  return `${formatHoobiizDate(d)} ${d.toLocaleTimeString('fr')}`;
}
