/* eslint-disable @typescript-eslint/no-magic-numbers */
import {FC, JSX} from 'react';
import styled from 'styled-components';

import {lighten} from '@shared-frontend/colors';
import {LinkAsButton} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';

import {LIGHT_ACCENT_RATIO} from '@src/components/core/theme_base';
import partnerPageCatCse from '@src/images/partner_page_cat_cse.png';
import partnerPageCatTourisme from '@src/images/partner_page_cat_tourisme.png';
import partnerPageCatWww from '@src/images/partner_page_cat_www.png';
import partnerPageCustomer from '@src/images/partner_page_customer.png';
import partnerPageHeader from '@src/images/partner_page_header.png';
import partnerPageIcon1 from '@src/images/partner_page_icon1.png';
import partnerPageIcon2 from '@src/images/partner_page_icon2.png';
import partnerPageIcon3 from '@src/images/partner_page_icon3.png';
import partnerPageIcon4 from '@src/images/partner_page_icon4.png';
import partnerPageIcon5 from '@src/images/partner_page_icon5.png';
import partnerPageIcon6 from '@src/images/partner_page_icon6.png';
import partnerPagePartner1 from '@src/images/partner_page_partner1.png';
import partnerPagePartner2 from '@src/images/partner_page_partner2.png';
import partnerPagePartner3 from '@src/images/partner_page_partner3.png';
import partnerPagePartner4 from '@src/images/partner_page_partner4.png';
import partnerPagePartner5 from '@src/images/partner_page_partner5.png';
import partnerPageStarIcon from '@src/images/partner_page_star_icon.png';
import partnerPageStep1 from '@src/images/partner_page_step1.png';
import partnerPageStep2 from '@src/images/partner_page_step2.png';
import partnerPageStep3 from '@src/images/partner_page_step3.png';

interface PartnerPageProps {}

function makeIcon(image: ImageSrcData, width: number, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width}} height={100} alt={alt} />;
}

function makePartnerImage(image: ImageSrcData, width: number, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width}} height={80} alt={alt} />;
}

function makeHowItWorksIcon(image: ImageSrcData, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width: 187}} height={80} alt={alt} />;
}

function makeCatImage(image: ImageSrcData, width: number, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width}} height={100} alt={alt} />;
}

export const PartnerPage: FC<PartnerPageProps> = () => {
  return (
    <Wrapper>
      {/* HEADER */}
      <Image
        srcAndSizes={{srcData: partnerPageHeader, width: 1920}}
        width="calc(100% + 48px)"
        alt="Devenir partenaire !"
      />
      <HeaderText>
        Youpiiz.com ambitionne de devenir le premier site spécialisé dans la réservation d'activités
        de loisirs et de bien être en Europe.
      </HeaderText>
      <HeaderQuestion>Pourquoi référencer votre activité sur Youpiiz.com ?</HeaderQuestion>

      {/* GRID */}
      <Grid>
        <GridItem>
          <GridItemTitle>Inscription gratuite !</GridItemTitle>
          {makeIcon(partnerPageIcon1, 213, `Inscription gratuite !`)}
          <GridItemContent>
            L'inscription est gratuite, nous nous rémunérons uniquement sur les clients que nous
            vous apportons&nbsp;!
          </GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Visibilité optimale !</GridItemTitle>
          {makeIcon(partnerPageIcon2, 190, `Visibilité optimale !`)}
          <GridItemContent>
            Le choix de déploiement de notre site sur d'autres sites professionnels privés permet à
            votre activité de bénéficier d'une forte visibilité&nbsp;!
          </GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Jusqu'à 20% de clients supplémentaires !</GridItemTitle>
          {makeIcon(partnerPageIcon3, 173, `Jusqu'à 20% de clients supplémentaires !`)}
          <GridItemContent>
            Selon votre activité nous sommes en mesure de vous apporter jusqu'à 20% de clients
            supplémentaires&nbsp;!
          </GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Dynamisez vos périodes creuses !</GridItemTitle>
          {makeIcon(partnerPageIcon4, 183, `Dynamisez vos périodes creuses !`)}
          <GridItemContent>
            Notre outil de promotion vous permet d'attirer plus de monde quand vous en avez besoin.
          </GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Bénéficiez des subventions d'entreprises !</GridItemTitle>
          {makeIcon(partnerPageIcon5, 182, `Bénéficiez des subventions d'entreprises !`)}
          <GridItemContent>
            Nous mettons à disposition des entreprises des outils qui leurs permettent de prendre en
            charge une partie du prix de votre activité pour leurs salariés&nbsp;!
          </GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Système de vente adapté à votre activité !</GridItemTitle>
          {makeIcon(partnerPageIcon6, 324, `Système de vente adapté à votre activité !`)}
          <GridItemContent>
            Selon votre activité nous vous proposons différentes solutions avec ou sans réservation
            et possibilité de s'intégrer à votre logiciel existant&nbsp;!
          </GridItemContent>
        </GridItem>
      </Grid>

      {/* Partner Trust */}
      <PartnerHeader>Ils nous font déjà confiance&nbsp;:</PartnerHeader>
      <PartnersList>
        {makePartnerImage(partnerPagePartner1, 213, 'PortAventura Park')}
        {makePartnerImage(partnerPagePartner2, 283, 'Parc Astérix')}
        {makePartnerImage(partnerPagePartner3, 431, 'Disneyland Paris')}
        {makePartnerImage(partnerPagePartner4, 283, 'Futuroscope')}
        {makePartnerImage(partnerPagePartner5, 257, 'Incroyable Grévin')}
      </PartnersList>

      {/* How it works */}
      <HowItWorksHeader>
        <Image srcAndSizes={{srcData: partnerPageStarIcon, width: 417}} height={64} alt="star" />
        <HowItWorksTitle>Youpiiz.com comment ça marche&nbsp;?</HowItWorksTitle>
        <Image srcAndSizes={{srcData: partnerPageStarIcon, width: 417}} height={64} alt="star" />
      </HowItWorksHeader>
      <HowItWorksList>
        <HowItWorksItem>
          {makeHowItWorksIcon(partnerPageStep1, 'step 1')}
          <HowItWorksContent>Remplissez notre formulaire partenaire.</HowItWorksContent>
        </HowItWorksItem>
        <HowItWorksSeparator1 />
        <HowItWorksItem>
          {makeHowItWorksIcon(partnerPageStep2, 'step 2')}
          <HowItWorksContent>
            Votre activité est visible auprès de nos milliers de visiteurs.
          </HowItWorksContent>
        </HowItWorksItem>
        <HowItWorksSeparator2 />
        <HowItWorksItem>
          {makeHowItWorksIcon(partnerPageStep3, 'step 3')}
          <HowItWorksContent>Vous recevez vos réservations directement par mail.</HowItWorksContent>
        </HowItWorksItem>
      </HowItWorksList>

      {/* Commission */}
      <CommissionSection>
        <CommissionSep />
        <CommissionQuote>
          Notre commission varie selon votre activité et le système de vente choisit. Notre outils
          de gestion peut aussi devenir si vous le souhaitez votre outil principal. Vous pouvez
          arrêter ou mettre votre activité en pause quand vous le souhaitez.
        </CommissionQuote>
        <CommissionSep />
      </CommissionSection>

      {/* Avantages */}
      <AdvantagesWrapper>
        <AdvantagesList>
          {makeCatImage(partnerPageCatTourisme, 552, 'Tourisme')}
          {makeCatImage(partnerPageCatWww, 303, 'WWW')}
          {makeCatImage(partnerPageCatCse, 548, 'CSE')}
        </AdvantagesList>
        <AdvantagesQuote>
          Notre stratégie de développement nous permet d'optimisez la visibilité de vos activités.
          Vous êtes en mesure de choisir la typologie de client que vous souhaitez attirer.
        </AdvantagesQuote>
      </AdvantagesWrapper>

      {/* Testimony */}
      <Testimony>
        <TestimonyLeft>
          <Image
            srcAndSizes={{srcData: partnerPageCustomer, width: 1104}}
            height={200}
            alt="star"
          />
          <StyledLinkAsButton
            href="https://hdwj2jpq.forms.app/formulaire-devenez-partenaire-youpiiz-1"
            target="_blank"
            overrides={{borderRadius: 23}}
          >
            Rejoignez-nous
          </StyledLinkAsButton>
        </TestimonyLeft>
        <TestimonyRight>
          <TestimonySep />
          <TestimonyQuote>
            Nous gérons également un club de loisirs ; être présent sur une plateforme de
            réservation était indispensable pour nous, notre idéal n'existait pas, nous l'avons donc
            crée pour nous tous youpiiz.com !
          </TestimonyQuote>
          <TestimonySep />
          <TestimonyEmail>hello@youpiiz.com</TestimonyEmail>
          <TestimonySep />
        </TestimonyRight>
      </Testimony>
    </Wrapper>
  );
};

PartnerPage.displayName = 'PartnerPage';

const Wrapper = styled.div`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  & > picture:first-child {
    overflow: visible;
    margin: -16px 0 1vmax -48px;
  }
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 2vmax;
  line-height: 150%;
  padding: 0 16px;
`;

const HeaderQuestion = styled.div`
  text-align: center;
  font-size: 2.5vmax;
  font-weight: 700;
  line-height: 150%;
  padding: 16px;
  background-color: #faf6f2;
  border-radius: 16px;
  margin: 32px 16px 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-gap: 2vmax;
  justify-content: center;
  padding: 16px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: #faf6f2;
  border-radius: 8px;
  border: solid 2px #a3917f;
`;
const GridItemTitle = styled.div`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 120%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background: #ffffffaa;
  padding: 8px 16px;
  border-radius: 16px;
  box-shadow: 0 0 10px 2px #c4a86614;
`;
const GridItemContent = styled.div`
  text-align: center;
  line-height: 150%;
`;

const PartnerHeader = styled.div`
  text-align: center;
  font-size: 2vmax;
  font-weight: 600;
  line-height: 150%;
  margin: 1vmax 0 1.5vmax;
`;
const PartnersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const HowItWorksHeader = styled.div`
  display: flex;
  gap: 1vmax;
  align-items: center;
  justify-content: center;
  margin: 48px 1vmax 24px 1vmax;
  & > * {
    flex-shrink: 0;
  }
`;
const HowItWorksTitle = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  text-align: center;
  font-size: 2.5vmax;
  font-weight: 600;
  line-height: 150%;
  margin: 1.5vmax 0 1.5vmax;
`;
const HowItWorksList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
`;
const HowItWorksItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  max-width: 200px;
`;
const HowItWorksContent = styled.div`
  line-height: 150%;
  text-align: center;
`;
const HowItWorksSeparatorBase = styled.div`
  width: 6px;
  height: 75px;
  align-self: center;
  background-color: ${p => lighten(p.theme.main.accentColor, LIGHT_ACCENT_RATIO)};
`;
const HowItWorksSeparator1 = styled(HowItWorksSeparatorBase)`
  @media (max-width: 471px) {
    /* background-color: transparent; */
    display: none;
  }
`;
const HowItWorksSeparator2 = styled(HowItWorksSeparatorBase)`
  @media (max-width: 741px) {
    /* background-color: transparent; */
    display: none;
  }
`;

const CommissionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 32px;
`;
const CommissionSep = styled.div`
  width: 250px;
  height: 2px;
  background-color: ${p => lighten(p.theme.main.accentColor, LIGHT_ACCENT_RATIO)};
`;
const CommissionQuote = styled.div`
  font-style: italic;
  line-height: 150%;
  text-align: center;
  max-width: 800px;
`;

const AdvantagesWrapper = styled.div`
  margin: auto;
  margin-top: 32px;
  padding: 0 16px;
`;
const AdvantagesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px 64px;
`;
const AdvantagesQuote = styled.div`
  font-style: italic;
  line-height: 150%;
  text-align: center;
  max-width: 800px;
  margin-top: 32px;
`;

const Testimony = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  margin: 48px auto;
`;
const TestimonyLeft = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TestimonyRight = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;
const TestimonySep = styled.div`
  width: 200px;
  height: 2px;
  background-color: ${p => lighten(p.theme.main.accentColor, LIGHT_ACCENT_RATIO)};
`;
const TestimonyQuote = styled.div`
  font-style: italic;
  line-height: 150%;
  text-align: center;
  max-width: 300px;
`;
const TestimonyEmail = styled.div`
  text-align: center;
  font-weight: 600;
  color: #666;
  font-size: 19px;
`;
const StyledLinkAsButton = styled(LinkAsButton)`
  width: 100%;
`;
