import {Tuple3} from '@shared/lib/tuple_utils';

/* eslint-disable @typescript-eslint/no-magic-numbers */
function hexByte(byte: number): string {
  const hex = byte.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

/**
 *
 * @param hue 0 to 360 degrees
 * @param saturation 0 to 1
 * @param lightness 0 to 1
 */
export function hslToHex(hue: number, saturation: number, lightness: number): string {
  const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation;
  const huePrime = hue / 60;
  const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1));

  const huePrimeRounded = Math.floor(huePrime);
  let red = 0;
  let green = 0;
  let blue = 0;

  if (huePrimeRounded === 0) {
    red = chroma;
    green = secondComponent;
    blue = 0;
  } else if (huePrimeRounded === 1) {
    red = secondComponent;
    green = chroma;
    blue = 0;
  } else if (huePrimeRounded === 2) {
    red = 0;
    green = chroma;
    blue = secondComponent;
  } else if (huePrimeRounded === 3) {
    red = 0;
    green = secondComponent;
    blue = chroma;
  } else if (huePrimeRounded === 4) {
    red = secondComponent;
    green = 0;
    blue = chroma;
  } else if (huePrimeRounded === 5) {
    red = chroma;
    green = 0;
    blue = secondComponent;
  }

  const lightnessAdjustment = lightness - chroma / 2;
  red += lightnessAdjustment;
  green += lightnessAdjustment;
  blue += lightnessAdjustment;

  return `#${
    hexByte(Math.abs(Math.round(red * 255))) +
    hexByte(Math.abs(Math.round(green * 255))) +
    hexByte(Math.abs(Math.round(blue * 255)))
  }`;
}

const rgbHexRegex = /[\da-f]{6}|[\da-f]{3}/iu;
export function hexToRgb(hex: string): Rgb {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }
  const match = rgbHexRegex.exec(hex);
  if (!match) {
    return [0, 0, 0];
  }
  let colorString = match[0];

  if (match[0].length === 3) {
    colorString = [...colorString]
      .map(char => {
        return char + char;
      })
      .join('');
  }

  const integer = parseInt(colorString, 16);
  /* eslint-disable no-bitwise */
  const r = (integer >> 16) & 0xff;
  const g = (integer >> 8) & 0xff;
  const b = integer & 0xff;
  /* eslint-enable no-bitwise */

  return [r, g, b];
}

export function rgbToHex(rgb: Rgb): string {
  const [r, g, b] = rgb;
  const integer =
    // eslint-disable-next-line no-bitwise
    ((Math.round(r) & 0xff) << 16) + ((Math.round(g) & 0xff) << 8) + (Math.round(b) & 0xff);

  const string = integer.toString(16).toUpperCase();
  return '000000'.slice(string.length) + string;
}

export enum ContrastRatio {
  Low = 3,
  Medium = 4.5,
  High = 7,
}

type Rgb = Tuple3<number>;

export function relativeLuminance(rgbColor: Rgb): number {
  const lowc = 1 / 12.92; // low-gamma adjust coefficient
  const [r, g, b] = rgbColor.map(c => {
    const ratio = c / 255;
    return ratio <= 0.03928 ? ratio * lowc : ((ratio + 0.055) / 1.055) ** 2.4;
  }) as Rgb;
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

export function contrastRatio(rgbColor1: Rgb, rgbColor2: Rgb): number {
  const l1 = relativeLuminance(rgbColor1);
  const l2 = relativeLuminance(rgbColor2);
  return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

export function mixColors(color1: Rgb, color2: Rgb, color1Ratio: number): Rgb {
  const color2Ratio = 1 - Math.max(0, Math.min(1, color1Ratio));
  const r = Math.round(color1Ratio * color1[0] + color2Ratio * color2[0]);
  const g = Math.round(color1Ratio * color1[1] + color2Ratio * color2[1]);
  const b = Math.round(color1Ratio * color1[2] + color2Ratio * color2[2]);
  return [r, g, b];
}

//
//
//

export function getGrayForContrastOnColor(backgroundColor: string, ratio: ContrastRatio): string {
  const color = hexToRgb(backgroundColor);
  let bestForegroundColor: Rgb | undefined;
  let bestDifference = Infinity;
  for (let i = 0; i < 256; i++) {
    const c: Rgb = [i, i, i];
    const r = contrastRatio(color, c);
    const diff = r - ratio;
    if (diff > 0 && diff < bestDifference) {
      bestDifference = diff;
      bestForegroundColor = c;
    }
  }
  if (bestForegroundColor === undefined) {
    throw new Error(
      `Cannot find any gray that have a contrast ratio of at least ${ratio} on the color ${backgroundColor}`
    );
  }
  return `#${rgbToHex(bestForegroundColor)}`;
}

export function generatePalette(saturation: number, lightness: number): string[] {
  let hueIncrement = 360;
  let incrementIndex = 0;
  let currentHue = 0;

  const paletteSize = 100;
  const palette: string[] = [hslToHex(0, saturation, lightness)];

  while (palette.length < paletteSize) {
    currentHue += hueIncrement;
    if (currentHue >= 360) {
      hueIncrement /= 2;
      currentHue = (currentHue + hueIncrement) % 360;
      incrementIndex = 0;
      palette.push(hslToHex(Math.round(currentHue), saturation, lightness));
      continue;
    }
    incrementIndex++;
    if (incrementIndex % 2 === 1) {
      continue;
    }
    palette.push(hslToHex(Math.round(currentHue), saturation, lightness));
  }

  return palette;
}

export const baseChartPalette = generatePalette(0.8, 0.5);

export function gradient(from: string, to: string): (ratio: number) => string {
  const fromRgb = hexToRgb(from);
  const toRgb = hexToRgb(to);
  return ratio => `#${rgbToHex(mixColors(fromRgb, toRgb, ratio))}`;
}

//

export function lighten(color: string, ratio: number): string {
  const rgb = hexToRgb(color);
  const hsl = rgbToHsl(rgb[0], rgb[1], rgb[2]);
  const res = hslToHex(hsl.h, hsl.s, hsl.l + (1 - hsl.l) * ratio);
  return res;
}

function rgbToHsl(r: number, g: number, b: number): {h: number; s: number; l: number} {
  // Convert RGB values from 0-255 to 0-1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find min and max values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  // Calculate luminance
  const l = (max + min) / 2;

  let h = 0; // Hue
  let s = 0; // Saturation

  if (delta !== 0) {
    // Calculate saturation
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    // Calculate hue
    if (max === r) {
      h = (g - b) / delta + (g < b ? 6 : 0);
    } else if (max === g) {
      h = (b - r) / delta + 2;
    } else if (max === b) {
      h = (r - g) / delta + 4;
    }
    h *= 60; // Convert to degrees
  }

  return {h, s, l}; // Return HSL
}
