import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizStockId,
  HoobiizStockModePregenerated,
  HoobiizStockModeType,
  HoobiizTicketData,
  HoobiizTicketFileItem,
} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {GridColumns} from '@shared-frontend/components/core/grid';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {StockHint} from '@src/components/admin/activity_stock/activity_stock_form';
import {AddFilesButton} from '@src/components/admin/activity_stock/add_files_button';
import {HoobiizTicketDataForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_data_form';
import {HoobiizTicketFilesUploadLine} from '@src/components/admin/activity_stock/hoobiiz_ticket_files_upload_line';
import {fetchNewDocumentUrls} from '@src/components/admin/document_urls_store';
import {FormBlockAuto, FormLabel} from '@src/components/admin/form/form_fragments';

export interface HoobiizStockModePregeneratedTicket {
  id?: HoobiizStockId;
  mode: HoobiizStockModePregenerated;
  stockHint?: StockHint;
}
interface HoobiizStockModePregeneratedFormProps {
  initialData?: HoobiizStockModePregeneratedTicket[];
  onChange?: (
    newData: HoobiizStockModePregeneratedTicket[],
    deleted: HoobiizStockModePregeneratedTicket[]
  ) => void;
}

export const HoobiizStockModePregeneratedForm: FC<HoobiizStockModePregeneratedFormProps> = ({
  initialData,
  onChange,
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const [tickets, setTickets] = useState<HoobiizStockModePregeneratedTicket[]>(initialData ?? []);
  const [deletedTickets, setDeletedTickets] = useState<HoobiizStockModePregeneratedTicket[]>([]);
  useEffect(() => {
    fetchNewDocumentUrls(
      tickets.flatMap(t =>
        t.mode.data.files === undefined ? [] : t.mode.data.files.map(f => f.id)
      )
    );
    onChange?.(tickets, deletedTickets);
  }, [deletedTickets, onChange, tickets]);

  // Callback when the file upload completes
  const handleFileUploadSuccess = useCallback((file: File, item: HoobiizTicketFileItem) => {
    setFiles(files => files.filter(f => f !== file));
    setTickets(tickets => [
      ...tickets,
      {
        mode: {
          type: HoobiizStockModeType.Pregenerated,
          data: {files: [{id: item.id}]},
        },
      },
    ]);
  }, []);

  // Callback when clicking to add a ticket with a code
  const addCodeTicket = useCallback(() => {
    setTickets(tickets => [
      ...tickets,
      {mode: {type: HoobiizStockModeType.Pregenerated, data: {code: {value: ''}}}},
    ]);
  }, []);

  // Callback when a file upload is canceled
  const handleFileDelete = useCallback((file: File) => {
    setFiles(files => files.filter(f => f !== file));
  }, []);

  // Callback when a ticket is deleted
  const handleTicketDelete = useCallback(
    (data: HoobiizTicketData) => {
      const ticket = tickets.find(t => t.mode.data === data);
      if (ticket?.id === undefined) {
        // Delete a ticket that was not saved yet
        setTickets(tickets => tickets.filter(t => t.mode.data !== data));
      } else {
        // Delete a ticket that was
        setDeletedTickets(prev => [...prev, ticket]);
      }
    },
    [tickets]
  );

  // Callback when a ticket is put back
  const handleTicketRevert = useCallback((data: HoobiizTicketData) => {
    setDeletedTickets(prev => prev.filter(t => t.mode.data !== data));
  }, []);

  // Callback when a ticket data changes
  const handleTicketChange = useCallback(
    (oldData: HoobiizTicketData, newData: HoobiizTicketData) => {
      setTickets(tickets => {
        const index = tickets.findIndex(t => t.mode.data === oldData);
        const ticket = tickets[index];
        if (!ticket) {
          return tickets;
        }
        return [
          ...tickets.slice(0, index),
          {...ticket, mode: {...ticket.mode, data: newData}},
          ...tickets.slice(index + 1),
        ];
      });
    },
    []
  );

  return (
    <FormBlockAuto>
      <FormLabel $noMargin>TICKETS ({tickets.length})</FormLabel>
      <Buttons>
        <AddFilesButton syncState={setFiles}>Ajouter des tickets PDF</AddFilesButton>
        <Button onClick={addCodeTicket}>Ajouter un ticket avec code</Button>
      </Buttons>
      {files.length > 0 ? (
        <Fragment>
          <Spacing height={16} />
          <GridColumns $columns={4} $alignItems="center">
            {files.map(f => (
              <HoobiizTicketFilesUploadLine
                key={f.name + f.size + f.type + f.lastModified}
                file={f}
                onSuccess={handleFileUploadSuccess}
                onDelete={handleFileDelete}
              />
            ))}
          </GridColumns>
        </Fragment>
      ) : (
        EmptyFragment
      )}
      <Spacing height={16} />
      <Tickets>
        {tickets.sort(sortTicketsByStockHint).map((ticket, i) => (
          <HoobiizTicketDataForm
            key={i}
            data={ticket.mode.data}
            stockHint={ticket.stockHint}
            isDeleted={deletedTickets.includes(ticket)}
            onDelete={handleTicketDelete}
            onRevert={handleTicketRevert}
            onChange={handleTicketChange}
          />
        ))}
      </Tickets>
    </FormBlockAuto>
  );
};
HoobiizStockModePregeneratedForm.displayName = 'HoobiizStockModePregeneratedForm';

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;

const Tickets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

// Sort tickets by stockHint: remaining first, then reserved, then bought, then undefined, finally createdAt
function sortTicketsByStockHint(
  t1: HoobiizStockModePregeneratedTicket,
  t2: HoobiizStockModePregeneratedTicket
): number {
  const hint1 = t1.stockHint;
  const hint2 = t2.stockHint;
  if (hint1 === undefined && hint2 === undefined) {
    return 0;
  }
  if (hint1 === undefined) {
    return -1;
  }
  if (hint2 === undefined) {
    return 1;
  }
  if (hint1.remaining > 0 && hint2.remaining > 0) {
    return hint2.createdAt - hint1.createdAt;
  }
  if (hint1.remaining > 0) {
    return -1;
  }
  if (hint2.remaining > 0) {
    return 1;
  }
  if (hint1.reserved > 0 && hint2.reserved > 0) {
    return hint2.createdAt - hint1.createdAt;
  }
  if (hint1.reserved > 0) {
    return -1;
  }
  if (hint2.reserved > 0) {
    return 1;
  }
  if (hint1.bought > 0 && hint2.bought > 0) {
    return hint2.createdAt - hint1.createdAt;
  }
  if (hint1.bought > 0) {
    return -1;
  }
  return hint2.createdAt - hint1.createdAt;
}
