// Inspired from
// https://github.com/jaredLunde/react-hook/blob/master/packages/resize-observer/src/index.tsx

import {useEffect, useLayoutEffect, useRef} from 'react';

export function useResizeObserver<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  callback: UseResizeObserverCallback
): ResizeObserver {
  const resizeObserver = getResizeObserver();
  const storedCallback = useRef(callback);
  useEffect(() => {
    storedCallback.current = callback;
  });

  useLayoutEffect(() => {
    let didUnsubscribe = false;
    const targetEl = target && 'current' in target ? target.current : target;
    if (!targetEl) {
      return () => {};
    }

    function cb(entry: ResizeObserverEntry, observer: ResizeObserver): void {
      if (didUnsubscribe) {
        return;
      }
      storedCallback.current(entry, observer);
    }

    resizeObserver.subscribe(targetEl as HTMLElement, cb);

    return () => {
      didUnsubscribe = true;
      resizeObserver.unsubscribe(targetEl as HTMLElement, cb);
    };
  }, [target, resizeObserver, storedCallback]);

  return resizeObserver.observer;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createResizeObserver() {
  let ticking = false;
  let allEntries: ResizeObserverEntry[] = [];

  const callbacks = new Map<unknown, UseResizeObserverCallback[]>();

  const observer = new ResizeObserver((entries: ResizeObserverEntry[], obs: ResizeObserver) => {
    allEntries = [...allEntries, ...entries];
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const triggered = new Set<Element>();
        for (const allEntry of allEntries) {
          if (triggered.has(allEntry.target)) {
            continue;
          }
          triggered.add(allEntry.target);
          const cbs = callbacks.get(allEntry.target);
          if (cbs) {
            for (const cb of cbs) {
              cb(allEntry, obs);
            }
          }
        }
        allEntries = [];
        ticking = false;
      });
    }
    ticking = true;
  });

  return {
    observer,
    subscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      observer.observe(target);
      const cbs = callbacks.get(target) ?? [];
      cbs.push(callback);
      callbacks.set(target, cbs);
    },
    unsubscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      const cbs = callbacks.get(target) ?? [];
      if (cbs.length === 1) {
        observer.unobserve(target);
        callbacks.delete(target);
        return;
      }
      const cbIndex = cbs.indexOf(callback);
      if (cbIndex !== -1) {
        cbs.splice(cbIndex, 1);
      }
      callbacks.set(target, cbs);
    },
  };
}

let resizeObserverInternal: ReturnType<typeof createResizeObserver> | undefined;
function getResizeObserver(): ReturnType<typeof createResizeObserver> {
  if (!resizeObserverInternal) {
    resizeObserverInternal = createResizeObserver();
  }
  return resizeObserverInternal;
}

type UseResizeObserverCallback = (entry: ResizeObserverEntry, observer: ResizeObserver) => unknown;
