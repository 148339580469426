import {FC} from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizCat2Id} from '@shared/dynamo_model';

import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizCat3ListView} from '@src/components/ui/hoobiiz_cat3_list_view';

interface Cat2PageProps {}

export const Cat2Page: FC<Cat2PageProps> = () => {
  const {cat2Id: cat2IdStr = ''} = useParams();
  const cat2Id = cat2IdStr as HoobiizCat2Id;
  const {isMobile} = useResponsive();

  return (
    <Wrapper $isMobile={isMobile}>
      <HoobiizCat3ListView cat2Id={cat2Id} />
      <HoobiizActivityModule catId={cat2Id} cse={{mode: 'all', showCheckbox: true}} />
    </Wrapper>
  );
};

Cat2Page.displayName = 'Cat2Page';

const Wrapper = styled.div<{$isMobile: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({$isMobile}) => ($isMobile ? '12px' : '32px')};
  gap: 32px;
`;
