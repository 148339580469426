import {HoobiizMediaId} from '@shared/dynamo_model';
import {env, IS_LOCALHOST_ENV} from '@shared/env_constants';
import {FRONTEND_STATIC_SUBDOMAIN, trimWwwSubdomain} from '@shared/frontends/frontend_constant';
import {buildUrl, getFrontendProtocol} from '@shared/lib/domain_utils';
import {LocalEnvConstants} from '@shared/model/env_constants';
import {awsBucketHashes} from '@shared/terraform';

export function hoobiizMediaUrl(
  domain: string,
  id: HoobiizMediaId,
  version?: {ext: string; width: number; height: number}
): string {
  const filePath = version
    ? `/${id}_${version.width}_${version.height}.${version.ext}`
    : `/original/${id}`;

  const isPrivate = false; // hoobiiz website is not private
  const protocol = getFrontendProtocol(isPrivate);
  const port = IS_LOCALHOST_ENV ? (env as LocalEnvConstants).devPort : undefined;
  const staticDomain = `${FRONTEND_STATIC_SUBDOMAIN}.${trimWwwSubdomain(domain)}`;
  const path = `/${awsBucketHashes.HoobiizMedia}${filePath}`;

  return `${buildUrl({protocol, domain: staticDomain, port})}${path}`;
}
