import {FC, Fragment, useCallback, useState} from 'react';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';

import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

interface RegisterFormProps {
  initialEmail?: EmailString;
  emailDisabled?: boolean;
  noLegalNotice?: boolean;
  onRegisterSubmit: (email: EmailString, password: string) => Promise<void>;
  buttonOverrides?: Partial<FrontendTheme['button']>;
  inputOverrides?: Partial<FrontendTheme['input']>;
  navLinkOverrides?: Partial<FrontendTheme['link']>;
}

export const RegisterForm: FC<RegisterFormProps> = props => {
  const {
    initialEmail,
    emailDisabled,
    noLegalNotice,
    onRegisterSubmit,
    buttonOverrides,
    inputOverrides,
    navLinkOverrides,
  } = props;

  const [email, setEmail] = useState<EmailString | undefined>(initialEmail);
  const [password, setPassword] = useState<string | undefined>();

  const handleRegisterSubmit = useCallback(async () => {
    if (email === undefined || password === undefined) {
      notifyError(`Merci de renseigner un ${emailDisabled ? '' : `email et `}mot de passe`);
      return;
    }
    return onRegisterSubmit(email, password);
  }, [email, emailDisabled, onRegisterSubmit, password]);

  return (
    <Fragment>
      <Content>
        <FormInput>
          <Input
            width="100%"
            type="email"
            value={email}
            syncState={setEmail}
            placeholder="jean@exemple.com"
            label="ADRESSE EMAIL"
            disabled={emailDisabled}
            readOnly={emailDisabled}
            autoComplete="email"
            overrides={inputOverrides}
          />
        </FormInput>
        <FormInput>
          <Input
            width="100%"
            type="password"
            value={password}
            syncState={setPassword}
            label="MOT DE PASSE (min. 8 caractères)"
            autoFocus={emailDisabled}
            autoComplete="new-password"
            overrides={inputOverrides}
          />
        </FormInput>
        {noLegalNotice ? (
          EmptyFragment
        ) : (
          <Fragment>
            <Spacing height={28} />
            <LegalText>
              En continuant, vous reconnaissez avoir lu les{' '}
              <NavLink to="/terms" overrides={navLinkOverrides}>
                mentions légales
              </NavLink>
            </LegalText>
          </Fragment>
        )}
      </Content>
      <ButtonSeparator />
      <Button
        expand
        type="button"
        submit
        onClickAsync={handleRegisterSubmit}
        overrides={buttonOverrides}
      >
        Créer mon compte
      </Button>
    </Fragment>
  );
};

RegisterForm.displayName = 'RegisterForm';

const Content = styled.div`
  font-weight: 300;
  font-size: 34px;
  width: 100%;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const ButtonSeparator = styled.div`
  height: 12px;
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 40%;
  font-weight: 500;
`;
