import {FC, useEffect, useMemo} from 'react';
import {Navigate} from 'react-router';
import {useTheme} from 'styled-components';

import {HoobiizWhiteLabelingAuthType} from '@shared/dynamo_model';

import {sharedApiCall} from '@shared-frontend/api';
import {AuthPageProps} from '@shared-frontend/components/auth/base/auth_page_props';
import {LogoutPageModeDefault} from '@shared-frontend/components/auth/mode_default/logout_page_theme_0';
import {LogoutPageModeHero} from '@shared-frontend/components/auth/mode_hero/logout_page_mode_hero';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {notifyError} from '@shared-frontend/lib/notification';
import {setSession, useSession} from '@shared-frontend/lib/session_store';

export interface LogoutPageProps extends AuthPageProps {
  disabled?: boolean;
}

export const LogoutPage: FC<LogoutPageProps> = props => {
  const {disabled, wrapper, whiteLabeling} = props;
  const session = useSession();
  const isConnected = useMemo(() => 'sessionId' in session, [session]);

  const {
    main: {api},
  } = useTheme();

  useEffect(() => {
    if (disabled || !isConnected) {
      return;
    }

    // Clear the cookie
    sharedApiCall(api, '/logout', {})
      .then(({session}) => setSession(session))
      .catch(err => notifyError(err, {message: 'Erreur lors de la déconnexion'}));
  }, [disabled, api, isConnected]);

  if (!isConnected) {
    return <Navigate to="/" />;
  }

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return <LogoutPageModeHero whiteLabeling={whiteLabelingModeHero} />;
  }
  return <LogoutPageModeDefault wrapper={wrapper} />;
};

LogoutPage.displayName = 'LogoutPage';
