import {HoobiizMediaId} from '@shared/dynamo_model';
import {groupBy} from '@shared/lib/array_utils';
import {hostToUrl} from '@shared/lib/domain_utils';
import {isMediaId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {hoobiizMediaUrl} from '@shared/lib/hoobiiz/hoobiiz_media_url';
import {EnrichedHoobiizMedia} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {Defined, removeUndefined} from '@shared/lib/type_utils';
import {FullItem, SanitizedItem} from '@shared/model/search_tables';

import {
  ImageDimension,
  ImageDimensionValue,
  ImageFullProps,
} from '@shared-frontend/components/core/image';

export type AnyHoobiizMedia =
  | SanitizedItem<'HoobiizMedia'>
  | {media?: FullItem<'HoobiizMedia'>; id: HoobiizMediaId};

export function hoobiizMediaToImageSrcData(host: string, media?: AnyHoobiizMedia): ImageSrcData {
  const extractedMedia = ((media && 'media' in media ? media.media : media) ?? {}) as Partial<
    Defined<AnyHoobiizMedia['media']>
  >;
  const id = media?.id;
  const {versions = [], placeholder} = extractedMedia;
  const {base64 = 'UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAkA4JaQAA3AA/vYkAAA='} =
    placeholder ?? {};
  versions.sort((v1, v2) => v2.width - v1.width);
  const [largest] = versions;

  const byMimeTypes = groupBy(versions, v => v.mimeType);
  const preferedMimeTypes = ['image/webp', 'image/png'];
  const sources = removeUndefined([
    ...preferedMimeTypes.map(mimeType => byMimeTypes.get(mimeType)),
    ...[...byMimeTypes.values()].filter(v => !preferedMimeTypes.includes(v[0].mimeType)),
  ]);
  const domain = new URL(hostToUrl(host)).hostname;

  const thumbnail = `data:image/webp;base64,${base64}`;

  return {
    sources:
      id === undefined
        ? []
        : sources.map(v => ({
            type: v[0].mimeType,
            srcset: v
              .map(
                ({ext, width, height}) =>
                  `${hoobiizMediaUrl(domain, id, {ext, width, height})} ${width}w`
              )
              .join(', '),
          })),
    src:
      id === undefined
        ? thumbnail
        : largest === undefined
          ? hoobiizMediaUrl(domain, id)
          : hoobiizMediaUrl(domain, id, {
              ext: largest.ext,
              width: largest.width,
              height: largest.height,
            }),
    aspectRatio: largest === undefined ? '1/1' : `${largest.width}/${largest.height}`,
    thumbnail,
  };
}

export function hoobiizMediaToImageProps(
  host: string,
  media: AnyHoobiizMedia | undefined,
  size: ImageDimension,
  thresholds?: Record<number, ImageDimensionValue>
): ImageFullProps {
  return {
    alt: '',
    srcAndSizes: {
      srcData: hoobiizMediaToImageSrcData(host, media),
      width: 'width' in size ? size.width : '100%',
      thresholds,
    },
    rawUrls: true,
    ...size,
  };
}

export function enrichHoobiizMedia<T>(
  data: T,
  mediaByIds: Map<HoobiizMediaId, SanitizedItem<'HoobiizMedia'>>
): EnrichedHoobiizMedia<T> {
  if (typeof data === 'string' && isMediaId(data)) {
    return mediaByIds.get(data) as EnrichedHoobiizMedia<T>;
  }
  if (Array.isArray(data)) {
    return data.map(el => enrichHoobiizMedia(el, mediaByIds)) as unknown as EnrichedHoobiizMedia<T>;
  }
  // eslint-disable-next-line no-null/no-null
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([id, value]) => [id, enrichHoobiizMedia(value, mediaByIds)])
    ) as unknown as EnrichedHoobiizMedia<T>;
  }
  return data as EnrichedHoobiizMedia<T>;
}

export function extractHoobiizMediaIds(data: unknown): HoobiizMediaId[] {
  if (typeof data === 'string' && isMediaId(data)) {
    return [data];
  }
  if (Array.isArray(data)) {
    return data.flatMap(extractHoobiizMediaIds);
  }
  // eslint-disable-next-line no-null/no-null
  if (typeof data === 'object' && data !== null) {
    return Object.values(data).flatMap(extractHoobiizMediaIds);
  }
  return [];
}
