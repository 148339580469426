import {FC, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {styled, useTheme} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizTicketFileId, HoobiizTicketFileItem} from '@shared/dynamo_model';
import {uidUnsafe} from '@shared/lib/rand';
import {neverHappens} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {bytesToString} from '@shared-frontend/components/core/bytes_size';
import {ProgressBar} from '@shared-frontend/components/core/progress_bar';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {Colors} from '@src/components/core/theme_base';

interface HoobiizTicketFilesUploadLineProps {
  file: File;
  onSuccess: (file: File, item: HoobiizTicketFileItem) => void;
  onDelete: (file: File) => void;
}

type UploadStatus =
  | {type: 'get-upload-url'}
  | {type: 'uploading-file'; progress?: number}
  | {type: 'finishing'}
  | {type: 'success'; fileItem: HoobiizTicketFileItem}
  | {type: 'failed'; err?: unknown; msg: string};

export const HoobiizTicketFilesUploadLine: FC<HoobiizTicketFilesUploadLineProps> = props => {
  const {file, onSuccess, onDelete} = props;
  const theme = useTheme();

  const uploadId = useRef('');
  const [status, setStatus] = useState<UploadStatus | undefined>();

  const finishUpload = useCallback(
    (file: File, id: HoobiizTicketFileId) => {
      const currentUploadId = uploadId.current;
      setStatus({type: 'finishing'});
      apiCall(HoobiizApi, '/admin/finish-ticket-file-upload', {id})
        .then(({fileItem}) => {
          if (uploadId.current !== currentUploadId) {
            return;
          }
          setStatus({type: 'success', fileItem});
          onSuccess(file, fileItem);
        })
        .catch(err => {
          if (uploadId.current !== currentUploadId) {
            return;
          }
          setStatus({type: 'failed', err, msg: `Erreur lors de la finalization de l'upload`});
        });
    },
    [onSuccess]
  );

  const startUpload = useCallback(
    (file: File, id: HoobiizTicketFileId, url: string) => {
      const currentUploadId = uploadId.current;
      setStatus({type: 'uploading-file', progress: 0});

      // Prepare the request
      const xhr = new XMLHttpRequest();

      // Upload starts
      xhr.upload.addEventListener('loadstart', () => {
        if (uploadId.current !== currentUploadId) {
          xhr.abort();
          return;
        }
        setStatus({type: 'uploading-file', progress: 0});
      });

      // Upload in progress
      xhr.upload.addEventListener('progress', event => {
        if (uploadId.current !== currentUploadId) {
          xhr.abort();
          return;
        }
        setStatus({type: 'uploading-file', progress: event.loaded / event.total});
      });

      // Upload completed
      xhr.upload.addEventListener('load', () => {
        if (uploadId.current !== currentUploadId) {
          xhr.abort();
          return;
        }
        setStatus({type: 'uploading-file', progress: 1});
        finishUpload(file, id);
      });

      // Upload failure
      const failureHandler = (msg: string) => {
        return () => {
          if (uploadId.current !== currentUploadId) {
            return;
          }
          setStatus({type: 'failed', msg});
        };
      };
      xhr.upload.addEventListener('error', failureHandler(`Échec`));
      xhr.upload.addEventListener('abort', failureHandler(`Upload annulé`));
      xhr.upload.addEventListener('timeout', failureHandler(`Upload timeout`));

      // Start the upload
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    },
    [finishUpload]
  );

  const prepareUpload = useCallback(
    (file: File) => {
      const currentUploadId = uploadId.current;
      setStatus({type: 'get-upload-url'});
      apiCall(HoobiizApi, '/admin/start-ticket-file-upload', {mimeType: file.type})
        .then(({id, url}) => {
          if (uploadId.current !== currentUploadId) {
            return;
          }
          startUpload(file, id, url);
        })
        .catch(err => {
          if (uploadId.current !== currentUploadId) {
            return;
          }
          setStatus({type: 'failed', err, msg: `Erreur lors de la préparation de l'upload`});
        });
    },
    [startUpload]
  );

  const handleDeleteClick = useCallback(() => {
    uploadId.current = uidUnsafe();
    onDelete(file);
  }, [file, onDelete]);

  useEffect(() => {
    uploadId.current = uidUnsafe();
    prepareUpload(file);
  }, [file, prepareUpload]);

  let progress = 0;
  let msg = '';
  let stripes = false;
  let canDelete = false;
  let canCancel = false;
  if (status) {
    if (status.type === 'get-upload-url') {
      progress = 0;
      msg = 'préparation';
      stripes = true;
      canCancel = true;
    } else if (status.type === 'uploading-file') {
      progress = status.progress ?? 0;
      msg = `${Math.round(100 * progress)} %`;
      canCancel = true;
    } else if (status.type === 'finishing') {
      progress = 1;
      msg = 'finalization';
      stripes = true;
      canCancel = true;
    } else if (status.type === 'success') {
      progress = 1;
      msg = 'terminé';
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (status.type === 'failed') {
      progress = 1;
      msg = status.msg;
      canDelete = true;
    } else {
      neverHappens(status);
    }
  }

  return (
    <Fragment>
      <FileName title={file.name}>{file.name}</FileName>
      <FileSize>{bytesToString(file.size)}</FileSize>
      <ProgressBar current={progress} max={1} stripes={stripes} width={300}>
        {msg}
      </ProgressBar>
      {canCancel ? (
        <ButtonAsLink onClick={handleDeleteClick}>Annuler</ButtonAsLink>
      ) : canDelete ? (
        <SvgIcon
          onClick={handleDeleteClick}
          name="Trash"
          color={'#000000'}
          colorHover={theme.link.textColorHover}
        />
      ) : (
        <div />
      )}
    </Fragment>
  );
};
HoobiizTicketFilesUploadLine.displayName = 'HoobiizTicketFilesUploadLine';

const FileName = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.div`
  font-size: 14px;
  color: ${Colors.Grey};
`;
