import {useMemo} from 'react';

import {isNonEmptyArray} from '@shared/lib/type_utils';

import {useSession} from '@shared-frontend/lib/session_store';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

interface NavHeight {
  headerHeight: number;
  footerHeight: number;
  adminBarHeight: number;
}

export function useNavHeight(): NavHeight {
  const {isMobile, isTablet} = useResponsive();
  const session = useSession();
  const isAdmin =
    'sessionId' in session && (isNonEmptyArray(session.adminGroups) || session.isAdmin);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const adminBarHeight = isAdmin ? 32 : 0;

  const navHeight = useMemo(() => {
    return isMobile
      ? {
          headerHeight: 64,
          footerHeight: 170,
          adminBarHeight,
        }
      : isTablet
        ? {
            headerHeight: 64,
            footerHeight: 50,
            adminBarHeight,
          }
        : {
            headerHeight: 91,
            footerHeight: 50,
            adminBarHeight,
          };
  }, [adminBarHeight, isMobile, isTablet]);

  return navHeight;
}
