import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {sharedApiCall} from '@shared-frontend/api';
import {AuthPageProps} from '@shared-frontend/components/auth/base/auth_page_props';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {setSession} from '@shared-frontend/lib/session_store';
import {useParams} from '@shared-frontend/lib/use_typed_params';

export interface LinkPageProps extends AuthPageProps {
  disabled?: boolean;
}

export const LinkPage: FC<LinkPageProps> = props => {
  const {id} = useParams<{id: string}>();
  const {disabled} = props;
  const {
    main: {api},
    auth: {postLoginPage},
  } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (id === undefined) {
      navigate('/');
    } else {
      sharedApiCall(api, '/link', {id})
        .then(data => {
          if (data.success) {
            setSession(data.session);
            navigate(postLoginPage);
          } else {
            navigate('/');
          }
        })
        .catch(err => {
          notifyError(err);
          navigate('/');
        });
    }
  }, [navigate, id, api, postLoginPage, disabled]);

  return EmptyFragment;
};

LinkPage.displayName = 'LinkPage';
