import styled from 'styled-components';

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
  font-size: 14px;
  color: #555;
`;
