import styled from 'styled-components';

import {ComponentClass, Custom, wrapElements} from '@shared-frontend/lib/react';

interface ModeDefaultTemplateProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  children: React.ReactNode;
}

export const ModeDefaultTemplate: Custom<ModeDefaultTemplateProps, 'form'> = props => {
  const {wrapper, children, ...rest} = props;

  const content = <Form {...rest}>{children}</Form>;
  return wrapper ? wrapElements(Array.isArray(wrapper) ? wrapper : [wrapper], content) : content;
};

ModeDefaultTemplate.displayName = 'ModeDefaultTemplate';

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  height: 100%;
  margin: auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.main.textColor};
  text-align: center;
  line-height: 1.5;
`;
