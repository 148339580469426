import {FC, Fragment, useCallback, useMemo} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuth,
  HoobiizWhiteLabelingAuthModeHero,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {EnrichedHoobiizMedia} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {DeepPartial, removeUndefined} from '@shared/lib/type_utils';

import {RegisterPage} from '@shared-frontend/components/auth/base/register_page';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {useEnrichedHoobiizMedia} from '@shared-frontend/lib/hoobiiz_media_store';

import {FormBlockFull, FormLabel} from '@src/components/admin/form/form_fragments';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {AdminUserAndGroupWhiteLabelingFormProps} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_page';
import {AutoScale} from '@src/components/admin/user_and_group/auto_scale';
import {adminInputTheme} from '@src/components/core/theme';
import {AdminWhiteLabelingValidator} from '@src/lib/hoobiiz_admin_white_labeling';

export type AdminWhiteLabelingModeHeroAuth = HoobiizWhiteLabelingAuth & {
  type: HoobiizWhiteLabelingAuthType.ModeHero;
};

export const isValidWhiteLabelingAuthModeHero: AdminWhiteLabelingValidator<
  HoobiizWhiteLabelingAuthModeHero
> = data => {
  if (!data) {
    return {valid: false};
  }

  return {
    valid: true,
    data: {
      ...data,
      type: HoobiizWhiteLabelingAuthType.ModeHero,
    },
  };
};

function useWhiteLabeling(
  labeling?: DeepPartial<HoobiizWhiteLabeling>
): EnrichedHoobiizMedia<DeepPartial<HoobiizWhiteLabeling>> {
  return useEnrichedHoobiizMedia(labeling) ?? {};
}

export const AdminUserAndGroupWhiteLabelingAuthModeHero: FC<
  AdminUserAndGroupWhiteLabelingFormProps<AdminWhiteLabelingModeHeroAuth>
> = props => {
  const {data, onChange, baseWhiteLabeling} = props;
  const handleTitleChange = useCallback(
    (title?: string) => onChange({...data, title}),
    [onChange, data]
  );

  const handleHeroMediaChange = useCallback(
    (statuses: MediaUploadStatus[]) => {
      const {hero, ...rest} = data ?? {};
      for (const status of statuses) {
        if (status.status === 'success') {
          onChange({...rest, hero: status.id});
          return;
        }
      }
      onChange({...rest, hero: undefined});
    },
    [data, onChange]
  );

  const handleLogoMediaChange = useCallback(
    (statuses: MediaUploadStatus[]) => {
      const {logo, ...rest} = data ?? {};
      for (const status of statuses) {
        if (status.status === 'success') {
          onChange({...rest, logo: status.id});
          return;
        }
      }
      onChange({...rest, logo: undefined});
    },
    [data, onChange]
  );

  const futureWhiteLabeling: HoobiizWhiteLabeling = useMemo(() => {
    return {
      ...baseWhiteLabeling,
      auth: {
        type: HoobiizWhiteLabelingAuthType.ModeHero,
        ...baseWhiteLabeling?.auth,
        register: data,
      },
    };
  }, [baseWhiteLabeling, data]);
  const previewWhiteLabeling = useWhiteLabeling(futureWhiteLabeling);

  return (
    <Wrapper>
      <Left>
        <FormBlockFull>
          <Textarea
            label="Titre"
            placeholder="BIENVENUE SUR VOTRE SITE"
            height={64}
            value={data?.title}
            syncState={handleTitleChange}
            overrides={adminInputTheme}
          />
        </FormBlockFull>
        <FormBlockFull>
          <FormLabel>LOGO</FormLabel>
          <MediaUpload
            initialMediaIds={removeUndefined([data?.logo])}
            onChange={handleLogoMediaChange}
          />
        </FormBlockFull>
        <FormBlockFull>
          <FormLabel>HERO</FormLabel>
          <MediaUpload
            initialMediaIds={removeUndefined([data?.hero])}
            onChange={handleHeroMediaChange}
          />
        </FormBlockFull>
      </Left>
      <Right>
        <AutoScale width={1280} height={720}>
          <RegisterPage
            whiteLabeling={previewWhiteLabeling}
            wrapper={Fragment}
            redirectWhenLoggedIn={false}
          />
        </AutoScale>
      </Right>
    </Wrapper>
  );
};

AdminUserAndGroupWhiteLabelingAuthModeHero.displayName =
  'AdminUserAndGroupWhiteLabelingAuthModeHero';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

const Left = styled.div`
  flex-shrink: 0;
  width: 400px;
`;

const Right = styled.div`
  flex-grow: 1;
`;
