import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';

import {setHoobiizSession, useHoobiizSession} from '@src/lib/stores';

interface AccountPageProps {}

export const AccountPage: FC<AccountPageProps> = () => {
  const session = useHoobiizSession();
  const [firstName, setFirstName] = useState(session?.userData.firstName ?? '');
  const [lastName, setLastName] = useState(session?.userData.lastName ?? '');
  const [phoneNumber, setPhoneNumber] = useState(session?.userData.phoneNumber ?? '');

  const handleUpdateSubmit = useCallback(async () => {
    if (!session) {
      notifyError(undefined, {message: `Vous n'êtes pas connecté`});
      return;
    }
    if (firstName.length === 0 || lastName.length === 0 || phoneNumber.length === 0) {
      notifyError(undefined, {message: 'Formulaire incomplet'});
      return;
    }
    const {userData} = await apiCall(HoobiizApi, '/update-account', {
      firstName,
      lastName,
      phoneNumber,
    });
    setHoobiizSession({...session, userData});
    showSuccess('Informations mise à jour');
  }, [firstName, lastName, phoneNumber, session]);

  if (!session) {
    return <Wrapper>Vous n'êtes pas connecté</Wrapper>;
  }

  return (
    <Form>
      <FormInput>
        <Input width="100%" value={session.email} disabled label="EMAIL" />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          value={firstName}
          syncState={setFirstName}
          placeholder={firstName}
          label="PRÉNOM"
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          value={lastName}
          syncState={setLastName}
          placeholder={lastName}
          label="NOM"
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          value={phoneNumber}
          syncState={setPhoneNumber}
          placeholder={phoneNumber}
          label="TÉLÉPHONE"
        />
      </FormInput>
      <Spacing height={28} />
      <Button expand type="button" submit onClickAsync={handleUpdateSubmit}>
        Mettre à jour
      </Button>
    </Form>
  );
};

AccountPage.displayName = 'AccountPage';

const Wrapper = styled.div`
  display: flex;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;
