import {FC, useCallback, useState} from 'react';
import {useNavigate} from 'react-router';
import {useTheme} from 'styled-components';

import {SessionItem} from '@shared/api/definitions/frontend_shared_api';
import {
  EmailString,
  FrontendUserAuthMethodType,
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {EnrichedHoobiizMedia} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {InviteId} from '@shared/lib/redis';
import {DeepPartial} from '@shared/lib/type_utils';

import {sharedApiCall} from '@shared-frontend/api';
import {RegisterInvitePageModeDefault} from '@shared-frontend/components/auth/mode_default/register_invite_page_theme_0';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {RegisterInvitePageModeHero} from '@shared-frontend/components/auth/mode_hero/register_invite_page_mode_hero';
import {notifyError} from '@shared-frontend/lib/notification';
import {ComponentClass, EmptyFragment} from '@shared-frontend/lib/react';
import {setSession} from '@shared-frontend/lib/session_store';
import {
  useOptionalBooleanQueryString,
  useOptionalStringQueryString,
} from '@shared-frontend/lib/use_query_string';

interface RegisterInvitePageProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  whiteLabeling: EnrichedHoobiizMedia<DeepPartial<HoobiizWhiteLabeling>> | undefined;
}

export const RegisterInvitePage: FC<RegisterInvitePageProps> = props => {
  const {wrapper, whiteLabeling} = props;
  const navigate = useNavigate();
  const {
    main: {api},
    auth: {postLoginPage},
  } = useTheme();
  const [inviteId] = useOptionalStringQueryString<InviteId>('inviteId');
  const [email] = useOptionalStringQueryString<EmailString>('email');
  const [authType] = useOptionalStringQueryString<FrontendUserAuthMethodType>('authType');
  const [isAdmin] = useOptionalBooleanQueryString('isAdmin');

  const [totpUri, setTotpUri] = useState<string | undefined>();
  const [nextSession, setNextSession] = useState<SessionItem | undefined>();

  const handleAccessClick = useCallback(() => {
    if (!nextSession) {
      notifyError(new Error('Échec'));
      return;
    }
    setSession(nextSession);
    navigate(postLoginPage);
  }, [navigate, nextSession, postLoginPage]);

  const register = useCallback(
    async (opts?: {password?: string}): Promise<void> => {
      const {password} = opts ?? {};
      if (email === undefined || inviteId === undefined || authType === undefined) {
        notifyError(new Error('Invalid form'));
        return;
      }
      return sharedApiCall(api, '/register-invite', {
        email,
        inviteId,
        password,
      })
        .then(data => {
          if (authType === FrontendUserAuthMethodType.MfaCode) {
            const method = data.authMethods.find(
              method => method.type === FrontendUserAuthMethodType.MfaCode
            );
            if (method?.type === FrontendUserAuthMethodType.MfaCode) {
              setTotpUri(method.totpUri);
            }
            setNextSession(data.session);
            return;
          }
          setSession(data.session);
          navigate(postLoginPage);
        })
        .catch(notifyError);
    },
    [authType, email, api, inviteId, navigate, postLoginPage]
  );

  const handleMfaRegisterSubmit = useCallback(async (): Promise<void> => register(), [register]);
  const handleMagicLinkRegisterSubmit = useCallback(
    async (): Promise<void> => register(),
    [register]
  );
  const handlePasswordRegisterSubmit = useCallback(
    async (email: EmailString, password: string): Promise<void> => {
      return register({password});
    },
    [register]
  );

  if (inviteId === undefined || email === undefined || authType === undefined) {
    return EmptyFragment;
  }

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return (
      <RegisterInvitePageModeHero
        whiteLabeling={whiteLabelingModeHero}
        email={email}
        totpUri={totpUri}
        authType={authType}
        isAdmin={isAdmin ?? false}
        handleAccessClick={handleAccessClick}
        handleMfaRegisterSubmit={handleMfaRegisterSubmit}
        handleMagicLinkRegisterSubmit={handleMagicLinkRegisterSubmit}
        handlePasswordRegisterSubmit={handlePasswordRegisterSubmit}
      />
    );
  }

  return (
    <RegisterInvitePageModeDefault
      wrapper={wrapper}
      email={email}
      totpUri={totpUri}
      authType={authType}
      isAdmin={isAdmin ?? false}
      handleAccessClick={handleAccessClick}
      handleMfaRegisterSubmit={handleMfaRegisterSubmit}
      handleMagicLinkRegisterSubmit={handleMagicLinkRegisterSubmit}
      handlePasswordRegisterSubmit={handlePasswordRegisterSubmit}
    />
  );
};
RegisterInvitePage.displayName = 'RegisterInvitePage';
