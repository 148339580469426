import {FC} from 'react';
import styled from 'styled-components';

import {
  ModeHeroAuthWhiteLabeling,
  ModeHeroTemplate,
} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';

interface RegisterLinkPageModeHeroProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
}

export const RegisterLinkPageModeHero: FC<RegisterLinkPageModeHeroProps> = props => {
  const {whiteLabeling} = props;

  return (
    <ModeHeroTemplate whiteLabeling={whiteLabeling}>
      <Wrapper>Finalisation de l'inscription...</Wrapper>
    </ModeHeroTemplate>
  );
};

RegisterLinkPageModeHero.displayName = 'RegisterLinkPageModeHero';

const Wrapper = styled.div`
  color: ${p => p.theme.main.accentTextColor};
  line-height: 1.5;
`;
