import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType, SearchApiQueryType} from '@shared/api/definitions/search_api';
import {HoobiizActivityId, HoobiizOfferTargetType} from '@shared/dynamo_model';
import {isOfferId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {Defined, isNull} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {NavButton} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {
  HoobiizActivityOfferForm,
  HoobiizActivityOfferFormProps,
} from '@src/components/admin/activity_offer/hoobiiz_activity_offer_form';
import {FormWrapper} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {PageWrapper} from '@src/components/admin/page_fragment';

interface HoobiizActivityOfferPageProps {
  activityId: HoobiizActivityId;
}

export const HoobiizActivityOfferPage: FC<HoobiizActivityOfferPageProps> = props => {
  const {activityId} = props;
  // const navigate = useNavigate();

  const [activity, setActivity] = useState<FullItem<'HoobiizActivity'> | undefined>();
  const [offers, setOffers] = useState<FullItem<'HoobiizOffer'>[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const reload = useCallback(() => {
    // Reset states
    setActivity(undefined);
    setOffers(undefined);
    setIsLoading(true);

    // Load all data
    Promise.all([
      apiCall(HoobiizApi, '/admin/search/get', {
        table: 'HoobiizActivity',
        id: activityId,
        mode: 'full',
        permissions: [],
      }),
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizOffer',
        gsi: {
          name: 'activityId',
          where: {type: 'equal', value: activityId},
        },
        mode: 'full',
        permissions: [],
      }),
    ])
      .then(([res1, res2]) => {
        const {item: activityItem} = res1 as SearchApiGetType<'HoobiizActivity', 'full'>['res'];
        if (!activityItem) {
          throw new Error('Activity not found');
        }
        setActivity(activityItem);
        const {items: offerItems} = res2 as SearchApiQueryType<'HoobiizOffer', 'full'>['res'];
        setOffers(offerItems.map(i => i.item).sort((s1, s2) => s1.createdAt - s2.createdAt));
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            "Échec du chargement des promotions de l'activité. Vous pouvez rafraichir la page pour réessayer",
        });
        setIsLoading(false);
      });
  }, [activityId]);

  useEffect(reload, [reload]);

  const handleSubmit = useCallback<HoobiizActivityOfferFormProps['onSubmit']>(
    async (data, el) => {
      const id = el.getAttribute('data-offer-id');
      if (isNull(id) || !isOfferId(id)) {
        return;
      }
      await apiCall(HoobiizApi, '/admin/update-offer', {updates: data, id})
        .then(() => {
          reload();
          showSuccess(`Promotion mise à jour`);
        })
        .catch(err => {
          reload();
          notifyError(err, {message: `Erreur lors de la mise à jour de la promotion`});
        });
    },
    [reload]
  );

  const handleDelete = useCallback<Defined<HoobiizActivityOfferFormProps['onDelete']>>(
    async el => {
      const id = el.getAttribute('data-offer-id');
      if (isNull(id) || !isOfferId(id)) {
        return;
      }
      await apiCall(HoobiizApi, '/admin/delete-offer', {id})
        .then(() => {
          reload();
          showSuccess(`Promotion supprimée`);
        })
        .catch(err => {
          reload();
          notifyError(err, {message: `Erreur lors de la suppression de la promotion`});
        });
    },
    [reload]
  );

  if (!activity || !offers || isLoading) {
    return (
      <LoadingPage>
        Chargement des promotions de l'activité
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }

  const offerForms = offers
    .filter(offer => offer.target.type === HoobiizOfferTargetType.Activity)
    .map((offer, i) => (
      <FormSection key={offer.id} title={`Promotion #${i + 1}`}>
        <HoobiizActivityOfferForm
          data-offer-id={offer.id}
          activity={activity}
          initialData={offer}
          submitButtonText="Modifier"
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </FormSection>
    ));

  return (
    <FormWrapper>
      <NavButton to={`/admin/activity/${activityId}/offer/create`}>Créer une promotion</NavButton>
      {offerForms}
    </FormWrapper>
  );
};
HoobiizActivityOfferPage.displayName = 'HoobiizActivityOfferPage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${p => p.theme.main.accentColor};
  opacity: 0.6;
`;
