import {FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {
  EmailString,
  FrontendUserAuthMethodType,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {asMap, asString, neverHappens} from '@shared/lib/type_utils';
import {MfaCode} from '@shared/model/auth/user';

import {sharedApiCall} from '@shared-frontend/api';
import {AuthPageProps} from '@shared-frontend/components/auth/base/auth_page_props';
import {LoginPageModeDefault} from '@shared-frontend/components/auth/mode_default/login_page_theme_0';
import {LoginPageModeHero} from '@shared-frontend/components/auth/mode_hero/login_page_mode_hero';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {InputProps} from '@shared-frontend/components/core/input_v2';
import {notifyError} from '@shared-frontend/lib/notification';
import {NULL_REF} from '@shared-frontend/lib/react';
import {setSession, useSession} from '@shared-frontend/lib/session_store';

export type LoginFormMode =
  | 'email-form'
  | 'email-password-form'
  | 'wait-for-magic-link'
  | 'mfa-form';

export interface LoginPageProps extends AuthPageProps {
  disabled?: boolean;
}

export const LoginPage: FC<LoginPageProps> = props => {
  const {disabled, wrapper, whiteLabeling} = props;
  const session = useSession();
  const preventAutoNav = useRef(false);
  const mfaCodeRef = useRef<HTMLInputElement>(NULL_REF);
  const navigate = useNavigate();
  const {state: locationState} = useLocation();
  const {
    main: {api},
    auth: {postLoginPage, userAuthTypes},
  } = useTheme();

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [mfaCode, setMfaCode] = useState<MfaCode | undefined>();
  const [formMode, setFormMode] = useState<LoginFormMode>(
    userAuthTypes.length === 1 && userAuthTypes[0] === FrontendUserAuthMethodType.Password
      ? 'email-password-form'
      : 'email-form'
  );

  const autoFocusPassword = useMemo(() => {
    return formMode === 'email-password-form' && email !== undefined && email.length > 0;
  }, [formMode, email]);

  const emailInputProps = useMemo<InputProps<string | undefined>>(
    () => ({
      width: '100%',
      type: 'email',
      value: email,
      syncState: setEmail,
      disabled: formMode === 'mfa-form',
      placeholder: 'jean@exemple.com',
      label: 'ADRESSE EMAIL',
      autoComplete: 'email',
      autoFocus: !autoFocusPassword,
    }),
    [email, formMode, autoFocusPassword]
  );

  const passwordInputProps = useMemo<InputProps<string | undefined>>(
    () => ({
      width: '100%',
      type: 'password',
      value: password,
      syncState: setPassword,
      label: 'MOT DE PASSE',
      autoComplete: 'current-password',
      autoFocus: autoFocusPassword,
    }),
    [password, autoFocusPassword]
  );

  const mfaCodeInputProps = useMemo<InputProps<MfaCode | undefined>>(
    () => ({
      ref: mfaCodeRef,
      width: '100%',
      type: 'number',
      value: mfaCode,
      syncState: setMfaCode,
      placeholder: '000000',
      label: "CODE D'AUTHENTIFICATION",
      autoComplete: 'one-time-code',
    }),
    [mfaCode]
  );

  useEffect(() => {
    if (!disabled && 'sessionId' in session && !preventAutoNav.current) {
      navigate(postLoginPage, {replace: true});
    }
  }, [disabled, navigate, postLoginPage, session]);

  useEffect(() => {
    if (formMode === 'mfa-form') {
      mfaCodeRef.current?.focus();
    }
  }, [formMode]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSubmitting(true);
      sharedApiCall(api, '/request-login', {email: email as EmailString})
        .then(data => {
          setIsSubmitting(false);
          if (data.authType === FrontendUserAuthMethodType.MagicLink) {
            setFormMode('wait-for-magic-link');
          } else if (data.authType === FrontendUserAuthMethodType.MfaCode) {
            setFormMode('mfa-form');
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (data.authType === FrontendUserAuthMethodType.Password) {
            setFormMode('email-password-form');
          } else {
            neverHappens(data.authType);
          }
        })
        .catch(err => {
          setIsSubmitting(false);
          notifyError(err);
        });
    },
    [api, email]
  );

  const handleLoginSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const authType =
        formMode === 'mfa-form'
          ? FrontendUserAuthMethodType.MfaCode
          : formMode === 'email-password-form'
            ? FrontendUserAuthMethodType.Password
            : undefined;
      if (authType === undefined) {
        notifyError(`Mode d'authentification invalide`);
        return;
      }
      setIsSubmitting(true);
      sharedApiCall(api, '/login', {
        email: email as EmailString,
        authType,
        password,
        mfaCode,
      })
        .then(data => {
          setIsSubmitting(false);
          if (data.success) {
            if ('redirect' in data) {
              window.location.href = data.redirect;
              return;
            }
            preventAutoNav.current = true;
            setSession(data.session);
            const from = asString(asMap(locationState, {}).from);
            if (from === undefined) {
              navigate(postLoginPage, {replace: true});
            } else {
              navigate(from, {replace: true});
            }
          } else {
            notifyError('Échec de la connexion');
          }
        })
        .catch(err => {
          setIsSubmitting(false);
          notifyError(err);
        });
    },
    [formMode, api, email, password, mfaCode, locationState, navigate, postLoginPage]
  );

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return (
      <LoginPageModeHero
        whiteLabeling={whiteLabelingModeHero}
        formMode={formMode}
        emailInputProps={emailInputProps}
        passwordInputProps={passwordInputProps}
        mfaCodeInputProps={mfaCodeInputProps}
        isSubmitting={isSubmitting}
        handleEmailSubmit={handleEmailSubmit}
        handleLoginSubmit={handleLoginSubmit}
      />
    );
  }
  return (
    <LoginPageModeDefault
      wrapper={wrapper}
      formMode={formMode}
      emailInputProps={emailInputProps}
      passwordInputProps={passwordInputProps}
      mfaCodeInputProps={mfaCodeInputProps}
      isSubmitting={isSubmitting}
      handleEmailSubmit={handleEmailSubmit}
      handleLoginSubmit={handleLoginSubmit}
    />
  );
};

LoginPage.displayName = 'LoginPage';
