import {FC} from 'react';
import {styled, useTheme} from 'styled-components';

import {Link} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

interface ExternalUrlLinkProps {
  url: string;
}

export const ExternalUrlLink: FC<ExternalUrlLinkProps> = ({url}) => {
  const theme = useTheme();
  return (
    <StyledLink href={url} target="_blank">
      {url}
      <SvgIcon name="ExternalLink" color={theme.link.textColorActive} size={13} />
    </StyledLink>
  );
};

ExternalUrlLink.displayName = 'ExternalUrlLink';

const StyledLink = styled(Link)`
  gap: 8px;
`;
