import {FC, ReactNode} from 'react';

import {AdminApp} from '@shared-frontend/components/admin/admin_app';

interface AdminAppWrapperProps {
  children?: ReactNode;
}

export const AdminAppWrapper: FC<AdminAppWrapperProps> = props => {
  const {children} = props;
  return (
    <AdminApp
      nav={[
        {path: '/admin/orders', label: 'Commandes', otherPaths: ['/admin']},
        {path: '/admin/vendor', label: 'Partenaires'},
        {path: '/admin/cat1', label: 'Catégories'},
        {path: '/admin/activity', label: 'Activités'},
        {path: '/admin/expert-ticket', label: 'Expert Ticket'},
        {path: '/admin/explorer', label: 'Explorer'},
        {path: '/admin/groups', label: 'Groupes'},
        {path: '/admin/users', label: 'Utilisateurs'},
      ]}
    >
      {children}
    </AdminApp>
  );
};

AdminAppWrapper.displayName = 'AdminAppWrapper';
